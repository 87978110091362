import React from "react";

export default function WhyChoosNetPlay() {
  return (
    <>
      <div className="fugu--content-section pt-5">
        <div className="container">
          <div className="fugu--content-top">
            <div className="row">
              <div className="col-xl-6 d-flex justify-content-center align-items-center order-xl-2">
                <div className="fugu--content-thumb">
                  <img
                    className="wow fadeInUpX w-100"
                    data-wow-delay=".10s"
                    src="assets/images/img-one/Benefits-of-netter.png"
                    alt=""
                    style={{
                      visibility: "visible",
                      animationDelay: "0.1s",
                      animationName: "fadeInUpX",
                    }}
                  />
                </div>
              </div>
              <div className="col-xl-6">
                <div className="fugu--default-content">
                  <h2 className="">Benefit of Net1Play</h2>
                  <div className="fugu--meta">
                    <ul>
                      <li className="">
                        <img src="assets/images/svg2/check.svg" alt="" />
                        Entertainment and Relaxation: Dive into a world of
                        entertainment and relaxation with Net1Play's extensive
                        selection of games. Whether you're looking for an
                        adrenaline rush or a moment of tranquility, Net1Play has
                        the perfect game for every mood.
                      </li>
                      <li className="">
                        <img src="assets/images/svg2/check.svg" alt="" />
                        Community Engagement: Immerse yourself in a supportive
                        and vibrant gaming community on Net1Play. Interact with
                        like-minded individuals, share experiences, and forge
                        new friendships as you embark on exciting gaming
                        adventures together.
                      </li>
                      <li className="">
                        <img src="assets/images/svg2/check.svg" alt="" />
                        Skill Development: Sharpen your gaming skills and
                        enhance your strategic thinking through participation in
                        tournaments and competitions. Compete against top
                        players, learn from your peers, and elevate your
                        gameplay to new heights on Net1Play.
                      </li>
                      <li className="">
                        <img src="assets/images/svg2/check.svg" alt="" />
                        Rewards and Recognition: Get rewarded for your
                        achievements and accomplishments on Net1Play. Earn
                        tokens, unlock achievements, and climb the leaderboards
                        to receive recognition for your gaming prowess and
                        dedication.
                      </li>
                      <li className="">
                        <img src="assets/images/svg2/check.svg" alt="" />
                        Convenience and Accessibility: Enjoy the convenience of
                        gaming anytime, anywhere, with Net1Play's cross-platform
                        compatibility. Whether you're at home, on the go, or
                        traveling, Net1Play ensures seamless access to your
                        favorite games across all your devices.
                      </li>
                      <li className="">
                        <img src="assets/images/svg2/check.svg" alt="" />
                        Trust and Integrity: Play with confidence on Net1Play,
                        knowing that your gaming experience is safeguarded by
                        stringent security measures and fair gameplay policies.
                        Rest assured that every game you play is free from
                        cheating and manipulation, guaranteeing a transparent
                        and trustworthy environment for all players.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
