import React from "react";

export default function NetBox() {
  return (
    <>
      <div className="fugu--content-section ">
        <div className="container">
          <div className="fugu--content-bottom">
            <div className="row align-items-center">
              <div className="col-xl-6 d-flex align-items-center">
                <img
                  src="assets/images/img-one/netbox.svg"
                  className="w-100"
                  alt=""
                />
              </div>
              <div className="col-xl-6">
                <div className="fugu--default-content pb-5">
                  <h2 className=" text-style-allcaps">Net1Box</h2>
                  <p className="">
                    Net1BOX is a unique product within the Network1 Chain
                    ecosystem, offering a limited supply of 10,000 tokens.
                    Holders of Net1BOX tokens gain exclusive access to support
                    and resources from the Network1 Chain organization. This
                    includes assistance with product development, listing on
                    exchanges, technical support, and marketing promotion
                    events. By holding Net1BOX tokens, users can leverage
                    unlimited support to build and grow their projects within
                    the network.
                  </p>
                </div>
                <div className="button-Whitepaper-w30">
                  <a className="w-button" href="/net-box">
                    Learn More
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
