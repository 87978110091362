import React from "react";

export default function WhyChoosNbox() {
  return (
    <>
      <div className="fugu--content-section bg- pt-5">
        <div className="container">
          <div className="fugu--content-top">
            <div className="row">
              <div className="col-xl-6 d-flex justify-content-center align-items-center order-xl-2">
                <div className="fugu--content-thumb">
                  <img
                    className="wow fadeInUpX w-100"
                    data-wow-delay=".10s"
                    src="assets/images/img-one/why-choose-us.(2).png"
                    alt=""
                    style={{
                      visibility: "visible",
                      animationDelay: "0.1s",
                      animationName: "fadeInUpX",
                    }}
                  />
                </div>
              </div>
              <div className="col-xl-6">
                <div className="fugu--default-content">
                  <h2 className="">Benefits of NBOX</h2>
                  <div className="fugu--meta">
                    <ul>
                      <li className="">
                        <img src="assets/images/svg2/check.svg" alt="" />
                        Seamless Transactions: NBOX enables fast, secure, and
                        low-cost transactions within the Network1 Chain
                        ecosystem, providing users with a convenient and
                        efficient way to transfer value.
                      </li>
                      <li className="">
                        <img src="assets/images/svg2/check.svg" alt="" />
                        Community Engagement: By incentivizing active
                        participation and engagement, NBOX fosters a vibrant and
                        thriving community within the Network1 Chain ecosystem,
                        driving growth and adoption.
                      </li>
                      <li className="">
                        <img src="assets/images/svg2/check.svg" alt="" />
                        Access to Premium Features: Holders of NBOX tokens gain
                        access to exclusive features and services, enhancing
                        their overall user experience and providing additional
                        value for their investment.
                      </li>
                      <li className="">
                        <img src="assets/images/svg2/check.svg" alt="" />
                        Governance Opportunities: NBOX token holders may have
                        the opportunity to participate in platform governance,
                        giving them a voice in shaping the future of the
                        Network1 Chain ecosystem.
                      </li>
                      <li className="">
                        <img src="assets/images/svg2/check.svg" alt="" />
                        Governance Opportunities: NBOX token holders may have
                        the opportunity to participate in platform governance,
                        giving them a voice in shaping the future of the
                        Network1 Chain ecosystem.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
