import React from "react";

export default function NetSoft() {
  return (
    <>
      <div className="fugu--content-section">
        <div className="container">
          <div className="fugu--content-bottom">
            <div className="row align-items-center">
              <div className="col-xl-6 d-flex align-items-center">
                <img
                  src="assets/images/img-one/netsoft.svg"
                  className="w-100"
                  alt=""
                />
              </div>
              <div className="col-xl-6">
                <div cassName="fugu--default-content">
                  <h2 className="text-white text-style-allcaps">Netsoft</h2>
                  <p className="text-white">
                    Netsoft - NetSoft is a software development company within
                    the Network1 Chain ecosystem, specializing in blockchain
                    solutions and decentralized applications (DApps). Leveraging
                    cutting-edge technology and expertise, NetSoft delivers
                    scalable and secure software solutions tailored to the needs
                    of network marketers. From smart contract development to
                    custom application integration, NetSoft empowers businesses
                    to thrive in the decentralized landscape.
                  </p>
                  <div className="button-Whitepaper-w30">
                    <a className="w-button" href="/net-soft">
                      Learn More
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
