import React from "react";

export default function NboxFeature() {
  return (
    <>
      <div className="fugu--content-section">
        <div className="container">
          <div className="fugu--content-bottom">
            <div className="row align-items-center pb-5">
              <div className="col-xl-6 d-flex align-items-center">
                <div className="fugu--content-thumb">
                  <img
                    className="wow fadeInLeft w-100"
                    data-wow-delay=".10s"
                    src="assets/images/img-one/Features-of-NetEx.png"
                    alt=""
                    style={{
                      visibility: "visible",
                      animationDelay: "0.1s",
                      animationName: "fadeInLeft",
                    }}
                  />
                </div>
              </div>
              <div className="col-xl-6">
                <div className="fugu--default-content">
                  <h2 className="">Features of NBOX</h2>
                  <div
                    className="fugu--accordion-one accordion-two"
                    id="accordionExample"
                  >
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingOne">
                        <button
                          className="accordion-button collapsed "
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOne"
                          aria-expanded="true"
                          aria-controls="collapseOne"
                        >
                          Utility Token
                        </button>
                      </h2>
                      <div
                        id="collapseOne"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingOne"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body ">
                          NBOX is a utility token specifically designed for use
                          within the Network1 Chain ecosystem. It serves as a
                          medium of exchange, allowing users to access premium
                          features, participate in network activities, and
                          unlock exclusive benefits within the platform.
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingTwo">
                        <button
                          className="accordion-button collapsed "
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseTwo"
                          aria-expanded="false"
                          aria-controls="collapseTwo"
                        >
                          Transaction Medium
                        </button>
                      </h2>
                      <div
                        id="collapseTwo"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingTwo"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body ">
                          NBOX facilitates seamless and cost-effective
                          transactions within the Network1 Chain ecosystem.
                          Users can use NBOX to conduct transactions, transfer
                          value, and pay for goods and services within the
                          platform, eliminating the need for traditional payment
                          methods and reducing transaction costs.
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingThree">
                        <button
                          className="accordion-button collapsed "
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseThree"
                          aria-expanded="false"
                          aria-controls="collapseThree"
                        >
                          Incentive Mechanism
                        </button>
                      </h2>
                      <div
                        id="collapseThree"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingThree"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body ">
                          NBOX serves as an incentive mechanism to reward active
                          participation and engagement within the Network1 Chain
                          community. Users can earn NBOX tokens through various
                          activities such as content creation, community
                          contributions, and network promotion, incentivizing
                          positive behaviors and fostering community growth.
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingFour">
                        <button
                          className="accordion-button collapsed "
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseFour"
                          aria-expanded="false"
                          aria-controls="collapseFour"
                        >
                          Access to Premium Features
                        </button>
                      </h2>
                      <div
                        id="collapseFour"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingFour"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body ">
                          Holders of NBOX tokens gain access to premium features
                          and services within the Network1 Chain ecosystem. This
                          includes access to exclusive content, priority
                          support, and enhanced functionality, providing
                          additional value and benefits to token holders.
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingFive">
                        <button
                          className="accordion-button collapsed "
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseFive"
                          aria-expanded="false"
                          aria-controls="collapseFive"
                        >
                          Governance Rights
                        </button>
                      </h2>
                      <div
                        id="collapseFive"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingFive"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body ">
                          NBOX token holders may also have governance rights
                          within the Network1 Chain ecosystem, allowing them to
                          participate in decision-making processes, vote on
                          platform upgrades, and shape the future direction of
                          the network. This democratic governance model ensures
                          that users have a voice in the evolution of the
                          platform.
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingSix">
                        <button
                          className="accordion-button collapsed "
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseSix"
                          aria-expanded="false"
                          aria-controls="collapseSix"
                        >
                          Integration with Network1 Products
                        </button>
                      </h2>
                      <div
                        id="collapseSix"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingSix"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body ">
                          NBOX tokens are seamlessly integrated with various
                          products and services offered within the Network1
                          Chain ecosystem, including Net1Ex, Net1FX, Net1Pay,
                          Netter, and others. This integration enhances the
                          utility and value of NBOX tokens, creating a cohesive
                          ecosystem where users can leverage their tokens across
                          multiple platforms.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
