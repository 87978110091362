import React from "react";

export default function Netter() {
  return (
    <>
      <div className="fugu--content-section  pt-5">
        <div className="container">
          <div className="fugu--content-top">
            <div className="row align-items-center">
              <div className="col-xl-6 d-flex justify-content-center align-items-center order-xl-2">
                <img
                  src="assets/images/img-one/netter.svg"
                  className="w-100"
                  alt=""
                />
              </div>
              <div className="col-xl-6">
                <div className="fugu--default-content pb-5">
                  <h2 className=" text-style-allcaps">Netter </h2>
                  <p className="">
                    Netter, a component of the Network1 Chain ecosystem, serves
                    as a comprehensive networking platform for network
                    marketers. With Netter, users can efficiently manage their
                    networks, track performance metrics, and engage with their
                    community. Its intuitive interface and robust features
                    empower network marketers to expand their reach and enhance
                    collaboration, ultimately driving business growth. Join
                    Netter today to revolutionize
                  </p>
                </div>
                <div className="button-Whitepaper-w30">
                  <a className="w-button" href="/netter">
                    Learn more
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
