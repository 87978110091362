import React from 'react'

export default function NetworkChainHero() {
    return (
        <>
            <div className="fugu--hero-section" style={{ backgroundImage: 'url(assets/images/all-img/v3/hero-bg.png)' }}>
                <div id="fugu--counter" />
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7">
                            <div className="fugu--hero-content">
                                <h1 className="wow fadeInUpX" data-wow-delay="0s">Your Gateway to the Crypto World</h1>
                                <p className="wow fadeInUpX" data-wow-delay="0.10s">Net1Ex is a cutting-edge cryptocurrency exchange platform designed to provide users with a seamless and secure trading experience. Whether you're a seasoned trader or new to the world of cryptocurrencies, Net1Ex offers a user-friendly interface, advanced trading tools, and a wide range of digital assets to suit your needs.</p>
                                <div className="fugu--btn-wrap fugu--hero-btn wow fadeInUpX" data-wow-delay="0.20s">
                                    <a className="fugu--btn bg-gray active" href="/coming-soon">Visit</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5">
                            <div className="fugu--hero-right" id="rotateOne">
                                <div className="fugu--card-wrap">
                                    <div className="fugu--card-thumb">
                                        <img src="assets/images/all-img/v3/hero-thumb2.png" alt="" />
                                    </div>
                                    <div className="fugu--card-data">
                                        <h3>The Exorians Universe</h3>
                                        <p>Pre-sale : 18 May 2023</p>
                                        <div className="fugu--card-footer">
                                            <div className="fugu--card-footer-data">
                                                <span>Mint Price:</span>
                                                <h4>0.194 ETH</h4>
                                            </div>
                                            <a className="fugu--btn btn-sm bg-white" href="#">Place a Bid</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="fugu--hero-shape1">
                        <img src="assets/images/all-img/v3/shape-hero1.png" alt="" />
                    </div>
                    <div className="fugu--hero-shape2" />
                </div>
            </div>
        </>
    )
}
