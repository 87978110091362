import React from "react";

function PaymentCurrency() {
  return (
    <div>
      <div className="fugu--content-section bg-white">
        <div className="container">
          <div className="fugu--content-bottom">
            <div className="row align-items-center pb-5">
              <div className="col-xl-6 d-flex align-items-center">
                <div className="fugu--content-thumb">
                  <img
                    className="wow fadeInLeft w-100"
                    data-wow-delay=".10s"
                    src="assets/images/img-one/Payment-Currency-for-Network1-Ecosystem.png"
                    alt=""
                    style={{
                      visibility: "visible",
                      animationDelay: "0.1s",
                      animationName: "fadeInLeft",
                    }}
                  />
                </div>
              </div>
              <div className="col-xl-6">
                <div className="fugu--default-content">
                  <h2 className="text-black">Payment Currency for Network1 Ecosystem</h2>
                  <p className="text-black">
                    USDN provides a reliable and decentralized means of value
                    transfer within that specific environment.
                  </p>
                  <p className="text-black">
                    As a stablecoin for the Network1 blockchain ecosystem, it
                    allows community members to access the global financial
                    ecosystem.
                  </p>
                  <p className="text-black">
                    Its peg to the value of $1 makes it ideal for transactions,
                    payments, and other financial activities on the Network1
                    blockchain.
                  </p>
                  <p className="text-black">
                    By functioning on the blockchain network, USDN ensures
                    consistency, stability, safety and decentralization.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PaymentCurrency;
