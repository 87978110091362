import React from "react";

export default function NetFX() {
  return (
    <>
      <div className="fugu--content-section">
        <div className="container">
          <div className="fugu--content-bottom">
            <div className="row align-items-center">
              <div className="col-xl-6 d-flex align-items-center">
                <img
                  src="assets/images/img-one/netfx.svg"
                  className="w-100"
                  alt=""
                />
              </div>
              <div className="col-xl-6">
                <div className="fugu--default-content">
                  <h2 className=" text-style-allcaps">Net1Fx</h2>
                  <p className="text-white">
                    Net1FX, part of the Network1 Chain ecosystem, offers a
                    secure and cost-effective platform for forex trading. Its
                    decentralized exchange ensures trustless transactions, while
                    its user-friendly interface and integrated tools simplify
                    trading for users. Join Net1FX to access transparent and
                    efficient forex trading within the Network1 Chain network.
                  </p>
                  <div className="button-Whitepaper-w30">
                    <a className="w-button" href="/net-fx">
                      Learn More
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
