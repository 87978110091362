import React from "react";

export default function Believe() {
  return (
    <>
      <div className="fugu--feature-section bg-white fugu--section-padding2">
        <div className="container">
          <div className="fugu--section-title2 pt-5">
            <div className="row">
              <div className="col-lg-7">
                <div className="fugu--default-content content-sm">
                  <h2 className="text-black">
                    Our strength is to change the path of our creativity trade
                    NET1
                  </h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-8 ">
                <div className="fugu--default-content">
                  <p className="text-black">
                    NETWORK1 ecosystem is connected with real world applications
                    including travelling platforms, e-commerce portals, games,
                    media platforms and dozens of others offering unique utility
                    for NET1 Coin.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-6 col-md-6">
              <div
                className="fugu--iconbox-wrap fugu--iconbox-wrap3 box-white-wrap4 wow fadeInUpX"
                data-wow-delay="0s"
                style={{
                  visibility: "visible",
                  animationDelay: "0s",
                  animationName: "fadeInUpX",
                }}
              >
                <div className="fugu--iconbox-thumb">
                  <img
                    src="assets/images/img-one/Safe-and-secure.png"
                    width="50"
                    alt=""
                  />
                </div>
                <div className="fugu--iconbox-data">
                  <h4 className="text-black">Safe and secure</h4>
                  <p className="text-black">
                    NET1 are safe when bought or sold on reputable exchanges,
                    &amp; they cannot be stolen if users properly secure.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-md-6">
              <div
                className="fugu--iconbox-wrap fugu--iconbox-wrap3 box-white-wrap4 wow fadeInUpX"
                data-wow-delay=".15s"
                style={{
                  visibility: "visible",
                  animationDelay: "0.15s",
                  animationName: "fadeInUpX",
                }}
              >
                <div className="fugu--iconbox-thumb">
                  <img
                    src="assets/images/img-one/Complete-transparency.png"
                    width="50"
                    alt=""
                  />
                </div>
                <div className="fugu--iconbox-data">
                  <h4 className="text-black">Complete transparency</h4>
                  <p className="text-black">
                    Transparency in a process involves it being completely
                    visible and open to scrutiny, so that it's clear.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-6">
              <div
                className="fugu--iconbox-wrap fugu--iconbox-wrap3 box-white-wrap4 wow fadeInUpX"
                data-wow-delay="0s"
                style={{
                  visibility: "visible",
                  animationDelay: "0s",
                  animationName: "fadeInUpX",
                }}
              >
                <div className="fugu--iconbox-thumb">
                  <img
                    src="assets/images/img-one/community_empowerment.png"
                    width="50"
                    alt=""
                  />
                </div>
                <div className="fugu--iconbox-data pb-2">
                  <h4 className="text-black">Trusted sanctuary</h4>
                  <p className="text-black">
                    Your trusted sanctuary in the digital realm, safeguarding
                    every transaction with unparalleled security
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-8 col-md-6">
              <div className="row">
                <div className="col-xl-6 col-md-6">
                  <div
                    className="fugu--iconbox-wrap fugu--iconbox-wrap3 box-white-wrap4 wow fadeInUpX"
                    data-wow-delay=".15s"
                    style={{
                      visibility: "visible",
                      animationDelay: "0.15s",
                      animationName: "fadeInUpX",
                    }}
                  >
                    <div className="fugu--iconbox-data">
                      <h4 className="text-black">$100</h4>
                      <p className="text-black">of Asset Secured MarketCap</p>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 col-md-6">
                  <div
                    className="fugu--iconbox-wrap fugu--iconbox-wrap3 box-white-wrap4 wow fadeInUpX"
                    data-wow-delay=".15s"
                    style={{
                      visibility: "visible",
                      animationDelay: "0.15s",
                      animationName: "fadeInUpX",
                    }}
                  >
                    <div className="fugu--iconbox-data">
                      <h4 className="text-black">10+</h4>
                      <p className="text-black">Ecosystem Partners</p>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 col-md-6">
                  <div
                    className="fugu--iconbox-wrap fugu--iconbox-wrap3 box-white-wrap4 wow fadeInUpX"
                    data-wow-delay=".15s"
                    style={{
                      visibility: "visible",
                      animationDelay: "0.15s",
                      animationName: "fadeInUpX",
                    }}
                  >
                    <div className="fugu--iconbox-data">
                      <h4 className="text-black">100%</h4>
                      <p className="text-black">Asset Surety</p>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 col-md-6">
                  <div
                    className="fugu--iconbox-wrap fugu--iconbox-wrap3 box-white-wrap4 wow fadeInUpX"
                    data-wow-delay=".15s"
                    style={{
                      visibility: "visible",
                      animationDelay: "0.15s",
                      animationName: "fadeInUpX",
                    }}
                  >
                    <div className="fugu--iconbox-data">
                      <h4 className="text-black">1M+</h4>
                      <p className="text-black">Unique Users Monthly</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
