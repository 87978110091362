import React from "react";

export default function StartBuilding() {
  return (
    <>
      <div className="fugu--feature-section fugu--section-padding2 ">
        <div className="container ">
          <div className="fugu--section-title2">
            <div className="row">
              <div className="col-lg-7">
                <div className="fugu--default-content content-sm">
                  <h2>Why is Network1 ideal for blockchain developers?</h2>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-4 col-md-6 pt-lg-3">
              <div
                className="fugu--iconbox-wrap fugu--iconbox-wrap3 fugu--iconbox-wrap4 wow fadeInUpX"
                data-wow-delay="0s"
                style={{
                  visibility: "visible",
                  animationDelay: "0s",
                  animationName: "fadeInUpX",
                }}
              >
                <img
                  src="assets/images/img-one/Safe-and-secure.png"
                  width={100}
                  alt=""
                />
                <div className="fugu--iconbox-data">
                  <h4>Utmost Secure </h4>
                  <p>
                    Network1 leverages the latest security algorithms &
                    encryptions to protect data, transactions & crypto assets.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-6">
              <div
                className="fugu--iconbox-wrap fugu--iconbox-wrap3 fugu--iconbox-wrap4 wow fadeInUpX"
                data-wow-delay=".15s"
                style={{
                  visibility: "visible",
                  animationDelay: "0.15s",
                  animationName: "fadeInUpX",
                }}
              >
                <img
                  src="assets/images/img-one/Complete-transparency.png"
                  width={100}
                  alt=""
                />
                <div className="fugu--iconbox-data">
                  <h4>Highly Scalable </h4>
                  <p>
                    Infrastructure of Network1 is highly scalable with it
                    enabling to build seamless decentralized applications
                    (dApps).
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-6 pt-lg-3">
              <div
                className="fugu--iconbox-wrap fugu--iconbox-wrap3 fugu--iconbox-wrap4 wow fadeInUpX"
                data-wow-delay=".25s"
                style={{
                  visibility: "visible",
                  animationDelay: "0.25s",
                  animationName: "fadeInUpX",
                }}
              >
                <img
                  src="assets/images/img-one/Creative-team.png"
                  width={100}
                  alt=""
                />
                <div className="fugu--iconbox-data">
                  <h4>Developer Centric </h4>
                  <p>
                    Network1 is a developer centric blockchain network that has
                    easy to use & integration environment.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
