import React from "react";

export default function NetPlayFeature() {
  return (
    <>
      <div className="fugu--content-section ">
        <div className="container">
          <div className="fugu--content-bottom">
            <div className="row align-items-center pb-5">
              <div className="col-xl-6 d-flex align-items-center">
                <div className="fugu--content-thumb">
                  <img
                    className="wow fadeInLeft w-100"
                    data-wow-delay=".10s"
                    src="assets/images/img-one/Features-of-NetEx.png"
                    alt=""
                    style={{
                      visibility: "visible",
                      animationDelay: "0.1s",
                      animationName: "fadeInLeft",
                    }}
                  />
                </div>
              </div>
              <div className="col-xl-6">
                <div className="fugu--default-content">
                  <h2 className="">Features of Net1Play</h2>
                  <div
                    className="fugu--accordion-one accordion-two"
                    id="accordionExample"
                  >
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingOne">
                        <button
                          className="accordion-button collapsed "
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOne"
                          aria-expanded="true"
                          aria-controls="collapseOne"
                        >
                          Diverse Game Selection
                        </button>
                      </h2>
                      <div
                        id="collapseOne"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingOne"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body ">
                          Net1Play offers a wide array of games across various
                          genres, catering to the diverse interests of players.
                          From action-packed adventures to mind-bending puzzles,
                          there's something for everyone to enjoy.
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingTwo">
                        <button
                          className="accordion-button collapsed "
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseTwo"
                          aria-expanded="false"
                          aria-controls="collapseTwo"
                        >
                          Tournaments and Competitions
                        </button>
                      </h2>
                      <div
                        id="collapseTwo"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingTwo"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body ">
                          Engage in thrilling tournaments and competitions
                          hosted on Net1Play. Showcase your skills, compete
                          against other players, and claim victory to earn
                          exciting rewards and prizes.
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingThree">
                        <button
                          className="accordion-button collapsed "
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseThree"
                          aria-expanded="false"
                          aria-controls="collapseThree"
                        >
                          Reward Mechanisms
                        </button>
                      </h2>
                      <div
                        id="collapseThree"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingThree"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body ">
                          Earn rewards through various in-game mechanisms,
                          including tokens, achievements, and leaderboards. As
                          you progress through games and reach milestones,
                          you'll be rewarded for your accomplishments,
                          motivating you to keep playing and improving.
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingFour">
                        <button
                          className="accordion-button collapsed "
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseFour"
                          aria-expanded="false"
                          aria-controls="collapseFour"
                        >
                          Social Interaction
                        </button>
                      </h2>
                      <div
                        id="collapseFour"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingFour"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body ">
                          Connect with fellow gamers through chat functionality,
                          forums, and social media integration. Form alliances,
                          collaborate in multiplayer games, and build lasting
                          friendships within the vibrant Net1Play community.
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingFive">
                        <button
                          className="accordion-button collapsed "
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseFive"
                          aria-expanded="false"
                          aria-controls="collapseFive"
                        >
                          Cross-Platform Compatibility
                        </button>
                      </h2>
                      <div
                        id="collapseFive"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingFive"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body ">
                          Enjoy gaming on your preferred device, whether it's a
                          desktop computer, smartphone, or tablet. Net1Play
                          ensures seamless compatibility across multiple
                          platforms, allowing you to access your favorite games
                          anytime, anywhere.
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingSix">
                        <button
                          className="accordion-button collapsed "
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseSix"
                          aria-expanded="false"
                          aria-controls="collapseSix"
                        >
                          Security and Fairness
                        </button>
                      </h2>
                      <div
                        id="collapseSix"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingSix"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body ">
                          Rest assured that your gaming experience on Net1Play
                          is secure and fair. The platform implements robust
                          security measures and anti-cheat mechanisms to
                          maintain integrity and prevent fraudulent activities,
                          ensuring a level playing field for all users.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
