import React from "react";

export default function CompanyHero() {
  return (
    <>
      <div
        className="fugu--hero-section3 bg-white "
        style={{
        //   backgroundImage: `url('assets/images/img-one/n1_network-bg.png')`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "50% 50%",
        }}
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-9">
              {/* <div
                className="video-text"
                style={{
                  visibility: "visible",
                  animationName: "fadeInUpX",
                  height: "500px",
                }}
              > */}
                <div className="pt-5">
                  <h1
                    className="span1"
                    data-wow-delay="0s"
                    style={{
                      visibility: "visible",
                      animationDelay: "0s",
                      animationName: "fadeInUpX",
                    }}
                  >
                    Apps. 
                    Businesses. 
                    Communities.
                  </h1>
                </div>
              {/* </div> */}
              {/* <video
                muted
                autoPlay
                loop
                id="myVideo"
                className="video-2 d-lg-block d-md-block d-sm-none d-none"
              >
                <source
                  src="assets/images/img-one/governance_hero.mp4"
                  type="video/mp4"
                />
              </video>
              <video
                muted
                autoPlay
                loop
                id="myVideo"
                className="video-2 d-lg-none d-md-none d-sm-block d-block"
              >
                <source
                  src="assets/images/img-one/governance_hero.mp4"
                  type="video/mp4"
                />
              </video> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
