import React from 'react'
import Header from '../Comman/Header'
import Footer from '../Comman/Footer'
import NetterHero from './NetterHero'
import NetterFeature from './NetterFeature'
import WhyChoosNetter from './WhyChoosNetter'
import TelosFooter from '../Telos_net/TelosFooter'

export default function Netter() {
    return (
        <>
            <Header />
            <NetterHero />
            <NetterFeature /> 
            <WhyChoosNetter />
            <TelosFooter/>
            {/* <Footer /> */}
        </>
    )
}
