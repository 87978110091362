import React from "react";

export default function WhyChoos() {
  return (
    <>
      <div
        className="fugu--content-section bg-white pt-5"
        style={{
          backgroundImage: `url('assets/images/img-one/n1_network-bg.png')`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "50% 50%",
        }}
      >
        <div className="container">
          <div className="fugu--content-top">
            <div className="row">
              <div className="col-xl-6 d-flex justify-content-center align-items-center order-xl-2">
                <div className="fugu--content-thumb">
                  <img
                    className="wow fadeInUpX w-100"
                    data-wow-delay=".10s"
                    src="assets/images/img-one/why-choose-us.png"
                    alt=""
                    style={{
                      visibility: "visible",
                      animationDelay: "0.1s",
                      animationName: "fadeInUpX",
                    }}
                  />
                </div>
              </div>
              <div className="col-xl-6">
                <div className="fugu--default-content">
                  <h2 className="">Benefits</h2>
                  <div className="fugu--meta">
                    <ul>
                      <li className="">
                        <img src="assets/images/svg2/check.svg" alt="" />
                        <h4 className="text-white">Ecosystem Synergy</h4>
                        <p className="">
                          By leveraging the robust infrastructure of Network1,
                          developers can build dApps that seamlessly integrate
                          with other projects within the ecosystem, creating a
                          synergistic network of applications that enhance each
                          other's value.
                        </p>
                      </li>
                      <li className="">
                        <img src="assets/images/svg2/check.svg" alt="" />
                        <h4 className="text-white">Accountability</h4>
                        <p className="">
                          Network1 Studio prioritizes growth with offering tools
                          and guidance designed to help dApps handle growth
                          efficiently. This ensures that projects are prepared
                          to meet the demands of an expanding user base
                        </p>
                      </li>
                      <li className="">
                        <img src="assets/images/svg2/check.svg" alt="" />
                        <h4 className="text-white">Security & Compliance</h4>
                        <p className="">
                          With a strong emphasis on security, Network1 Studio
                          provides resources to help developers build secure
                          applications that adhere to industry standards and
                          regulations. This proactive approach to compliance
                          helps to build trust with users and stakeholders.
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
