import React from "react";

export default function NetMeet() {
  return (
    <>
      <div className="fugu--content-section ">
        <div className="container">
          <div className="fugu--content-bottom">
            <div className="row align-items-center">
              <div className="col-xl-6 d-flex align-items-center">
                <img
                  src="assets/images/img-one/netmeet.svg"
                  className="w-100"
                  alt=""
                />
              </div>
              <div className="col-xl-6">
                <div className="fugu--default-content pb-5">
                  <h2 className=" text-style-allcaps">NetMeet</h2>
                  <p className="">
                    NetMeet is a cutting-edge virtual meeting platform
                    integrated within the Network1 Chain ecosystem. It offers
                    seamless and secure communication tools for network
                    marketers, enabling them to host meetings, webinars, and
                    conferences with ease. With features like screen sharing,
                    chat functionalities, and HD video quality, NetMeet fosters
                    collaboration and engagement among networkers worldwide.
                  </p>
                </div>
                <div className="button-Whitepaper-w30">
                  <a className="w-button" href="/net-meet">
                    Learn More
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
