import React from "react";

export default function Roadmap() {
  return (
    <>
      <div className="section fugu-section-padding3 bg-black">
        <div className="container">
          <div className="fugu-gallery-wrap3">
            <div className="row justify-content-center">
              <div className="col-lg-10">
                <div className="fugu-gallery-data4 text-center">
                  <h2 className="text-white">Roadmap</h2>
                  <p className="text-white fs-5">
                    The Network1 Chain Roadmap delineates the strategic vision
                    and timeline for the development and deployment of the
                    Network1 Chain platform.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <img
          src="assets/images/img-one/n1_network-roadmap.png"
          className="w-100"
          alt=""
        />
      </div>
    </>
  );
}
