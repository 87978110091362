import React from "react";

export default function Feature() {
  return (
    <>
      <div className="fugu--content-section ">
        <div className="container">
          <div className="fugu--content-bottom">
            <div className="row align-items-center pb-5">
              <div className="col-xl-6 d-flex align-items-center">
                <div className="fugu--content-thumb">
                  <img
                    className="wow fadeInLeft w-100"
                    data-wow-delay=".10s"
                    src="assets/images/img-one/network-studio-img.png"
                    alt=""
                    style={{
                      visibility: "visible",
                      animationDelay: "0.1s",
                      animationName: "fadeInLeft",
                    }}
                  />
                </div>
              </div>
              <div className="col-xl-6">
                <div className="fugu--default-content">
                  <h2 className="">Why Should You Apply?</h2>
                  <p className="">
                    Network1 Studio offers a comprehensive suite of services
                    that guide projects from the initial concept through to
                    full-scale deployment and beyond.
                  </p>
                  <p className="">
                    By combining technical support, financial backing, and
                    community engagement, Network1 Studio aims to streamline the
                    dApp development process, helping developers bring their
                    innovative ideas to life more efficiently.
                  </p>
                  <p className="">
                    Network1 Studio stands out from other dApp accelerators due
                    to its tailored approach and deep integration within the
                    Network1 ecosystem.
                  </p>
                  <p className="">
                    The platform provides developers with a targeted, supportive
                    environment that understands the unique challenges and
                    opportunities of building dApps on Network1.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
