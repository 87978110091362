import React from "react";

export default function Why() {
  return (
    <>
      <div className="fugu-cta-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="fugu-default-content large-content">
                <h2 className="">Submit your dApps</h2>
              </div>
            </div>
            <div className="col-lg-4 d-flex align-items-center">
              <div className="fugu-cta-app-btn-wrap">
                <a className="fugu--btn bg-blue active" href="/coming-soon">
                  Submit dApps
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
