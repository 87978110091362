import React from "react";
import Header from "../Comman/Header";
import Footer from "../Comman/Footer";
import SocialMedia from "../Comman/SocialMedia";
import StablecoinHero from "./StablecoinHero";
import FinancialFreedom from "./FinancialFreedom";
import UsingNUSD from "./UsingNUSD";
import PaymentCurrency from "./PaymentCurrency";
import WhyuseNUSD from "./WhyuseNUSD";
import StableValue from "./StableValue";
import TelosFooter from "../Telos_net/TelosFooter";


export default function Stablecoin() {
  return (
    <>
      <Header />
      <StablecoinHero/>
      <UsingNUSD/>
      <PaymentCurrency/>
      <StableValue/>
      <FinancialFreedom/>
      <WhyuseNUSD/>
      <SocialMedia/>  
      <TelosFooter/>
      {/* <Footer /> */}
    </>
  );
}
