import React from "react";

export default function Faq() {
  return (
    <>
      <div className="fugu--faq-section dark-version bg-white fugu--section-padding">
        <div className="container">
          <div className="fugu--section-title">
            <div className="fugu--default-content content-sm">
              <h2 className="span1">
                If you need to know anything about NET1s, just ask us!
              </h2>
            </div>
          </div>
          <div
            className="fugu--accordion-one accordion-four"
            id="accordionExample"
          >
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingOne">
                <button
                  className="accordion-button text-black"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  What is Network1 Chain?
                </button>
              </h2>
              <div
                id="collapseOne"
                className="accordion-collapse collapse show"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample"
              > text-black
                <div className="accordion-body text-black">
                  Network1 Chain is a blockchain platform specifically designed
                  for network marketing companies. It utilizes blockchain
                  technology to address the challenges faced by network
                  marketers, offering solutions for global transactions,
                  community engagement, and business growth.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingTwo">
                <button
                  className="accordion-button collapsed text-black"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                >
                  How does Network1 Chain benefit network marketers?
                </button>
              </h2>
              <div
                id="collapseTwo"
                className="accordion-collapse collapse text-black"
                aria-labelledby="headingTwo"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body text-black">
                  Network1 Chain provides network marketers with a secure and
                  transparent platform for conducting transactions, engaging
                  with their communities, and expanding their businesses
                  globally. It offers features such as secure transactions,
                  smart contracts, and community engagement tools to empower
                  network marketers.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingThree">
                <button
                  className="accordion-button collapsed text-black"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                >
                  What are the key components of the Network1 Chain ecosystem?
                </button>
              </h2>
              <div
                id="collapseThree"
                className="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body text-black">
                  The Network1 Chain ecosystem comprises various components,
                  including Net1Ex, Net1FX, Net1Pay, Netter, Mine Ur Network1,
                  and more. These components offer a comprehensive suite of
                  tools and services tailored to the needs of network marketers.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingFour">
                <button
                  className="accordion-button collapsed text-black"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFour"
                  aria-expanded="false"
                  aria-controls="collapseFour"
                >
                  What is Mine Ur Network1, and how does it work?
                </button>
              </h2>
              <div
                id="collapseFour"
                className="accordion-collapse collapse"
                aria-labelledby="headingFour"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body text-black">
                  Mine Ur Network1 is an affiliate-based program within the
                  Network1 Chain ecosystem. It allows users to earn rewards by
                  expanding and educating the network community, spreading
                  awareness about the Network1 Chain platform.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingFive">
                <button
                  className="accordion-button collapsed text-black"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFive"
                  aria-expanded="false"
                  aria-controls="collapseFive"
                >
                  What is the roadmap for Network1 Chain's development?
                </button>
              </h2>
              <div
                id="collapseFive"
                className="accordion-collapse collapse text-black"
                aria-labelledby="headingFive"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body text-black">
                  The roadmap outlines the development and deployment milestones
                  for Network1 Chain. It includes key timelines and planned
                  features for future releases, demonstrating the platform's
                  evolution and growth trajectory.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingFive">
                <button
                  className="accordion-button collapsed text-black"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFive"
                  aria-expanded="false"
                  aria-controls="collapseFive"
                >
                  How can I engage with the Network1 Chain community?
                </button>
              </h2>
              <div
                id="collapseFive"
                className="accordion-collapse collapse text-black"
                aria-labelledby="headingFive"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body text-black">
                  Network1 Chain offers various channels for community
                  engagement, including forums, social media platforms, and
                  events. Users can participate in discussions, share ideas, and
                  stay updated on the latest developments within the community.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingFive">
                <button
                  className="accordion-button collapsed text-black"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFive"
                  aria-expanded="false"
                  aria-controls="collapseFive"
                >
                  Is Network1 Chain secure and compliant with regulations?
                </button>
              </h2>
              <div
                id="collapseFive"
                className="accordion-collapse collapse"
                aria-labelledby="headingFive"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body text-black">
                  Yes, Network1 Chain prioritizes security and compliance with
                  regulatory standards. It implements robust security measures
                  and adheres to relevant regulations to ensure the safety and
                  integrity of its platform and users' data.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingFive">
                <button
                  className="accordion-button collapsed text-black"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFive"
                  aria-expanded="false"
                  aria-controls="collapseFive"
                >
                  What is Mine Ur Network1?
                </button>
              </h2>
              <div
                id="collapseFive"
                className="accordion-collapse collapse"
                aria-labelledby="headingFive"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body text-black">
                  Mine Ur Network1 is an affiliate-based program within the
                  Network1 Chain ecosystem designed to incentivize users to
                  expand and educate the network community. Participants can
                  earn rewards by spreading awareness about Network1 Chain and
                  contributing to its growth.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingFive">
                <button
                  className="accordion-button collapsed text-black"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFive"
                  aria-expanded="false"
                  aria-controls="collapseFive"
                >
                  How does Mine Ur Network1 work?
                </button>
              </h2>
              <div
                id="collapseFive"
                className="accordion-collapse collapse"
                aria-labelledby="headingFive"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body text-black">
                  Mine Ur Network1 allows users to earn rewards by inviting
                  others to join the Network1 Chain community and educating them
                  about the platform's benefits. Participants receive incentives
                  based on their contributions to expanding the network and
                  engaging with the community.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingFive">
                <button
                  className="accordion-button collapsed  text-black"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFive"
                  aria-expanded="false"
                  aria-controls="collapseFive"
                >
                  What are the rewards for participating in Mine Ur Network1?
                </button>
              </h2>
              <div
                id="collapseFive"
                className="accordion-collapse collapse"
                aria-labelledby="headingFive"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body text-black">
                  Participants in Mine Ur Network1 can earn rewards in the form
                  of Network1 Coin (NET1), the native token of the Network1
                  Chain platform. Rewards are based on factors such as the
                  number of referrals made, the engagement level of their
                  referrals, and their overall contribution to network growth.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingFive">
                <button
                  className="accordion-button collapsed text-black"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFive"
                  aria-expanded="false"
                  aria-controls="collapseFive"
                >
                  Can anyone participate in Mine Ur Network1?
                </button>
              </h2>
              <div
                id="collapseFive"
                className="accordion-collapse collapse"
                aria-labelledby="headingFive"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body text-black">
                  Yes, Mine Ur Network1 is open to anyone interested in
                  promoting and supporting the Network1 Chain platform. Users
                  can join the program and start earning rewards by inviting
                  others to join the community and participating in educational
                  activities.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingFive">
                <button
                  className="accordion-button collapsed text-black"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFive"
                  aria-expanded="false"
                  aria-controls="collapseFive"
                >
                  How do I track my rewards and progress in Mine Ur Network1?
                </button>
              </h2>
              <div
                id="collapseFive"
                className="accordion-collapse collapse"
                aria-labelledby="headingFive"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body text-black">
                  Network1 Chain provides tools and resources for participants
                  to track their rewards and progress in Mine Ur Network. Users
                  can access their reward status, referral statistics, and
                  engagement metrics through the Mine Ur Network1 dashboard or
                  platform interface.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingFive">
                <button
                  className="accordion-button collapsed text-black"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFive"
                  aria-expanded="false"
                  aria-controls="collapseFive"
                >
                  Is there a limit to the rewards I can earn in Mine Ur
                  Network1?
                </button>
              </h2>
              <div
                id="collapseFive"
                className="accordion-collapse collapse"
                aria-labelledby="headingFive"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body text-black">
                  There is no limit to the rewards users can earn in Mine Ur
                  Network1. Participants can continue to earn rewards based on
                  their ongoing contributions to network expansion and community
                  engagement.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingFive">
                <button
                  className="accordion-button collapsed text-black"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFive"
                  aria-expanded="false"
                  aria-controls="collapseFive"
                >
                  How can I get started with Mine Ur Network1?
                </button>
              </h2>
              <div
                id="collapseFive"
                className="accordion-collapse collapse"
                aria-labelledby="headingFive"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body text-black">
                  To get started with Mine Ur Network1 , simply sign up for an
                  account on the Network1 Chain platform and join the Mine Ur
                  Network1 program. Once enrolled, you can begin inviting others
                  to join the community and earning rewards for your efforts.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingFive">
                <button
                  className="accordion-button collapsed text-black"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFive"
                  aria-expanded="false"
                  aria-controls="collapseFive"
                >
                  Are there any educational resources available for Mine Ur
                  Network1 participants?
                </button>
              </h2>
              <div
                id="collapseFive"
                className="accordion-collapse collapse"
                aria-labelledby="headingFive" 
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body text-black">
                  Yes, Network1 Chain provides educational resources and
                  materials to help participants learn more about the platform
                  and effectively promote it to others. These resources may
                  include tutorials, guides, webinars, and marketing materials
                  tailored for Mine Ur Network1 participants.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingFive">
                <button
                  className="accordion-button collapsed text-black"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFive"
                  aria-expanded="false"
                  aria-controls="collapseFive"
                >
                  What is Network1 Chain Tokenomics?
                </button>
              </h2>
              <div
                id="collapseFive"
                className="accordion-collapse collapse"
                aria-labelledby="headingFive"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body text-black">
                  Network1 Chain Tokenomics refers to the economics and
                  distribution of Network1 Coin (NET1), the native
                  cryptocurrency of the Network1 Chain platform. It encompasses
                  factors such as token supply, distribution mechanisms, utility
                  within the ecosystem, and the overall economic model of the
                  network.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingFive">
                <button
                  className="accordion-button collapsed text-black"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFive"
                  aria-expanded="false"
                  aria-controls="collapseFive"
                >
                  What is the total supply of Network1 Coin (NET1)?
                </button>
              </h2>
              <div
                id="collapseFive"
                className="accordion-collapse collapse"
                aria-labelledby="headingFive"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body text-black">
                  The total supply of Network1 Coin (NET1) is 2,21,000,000 NET1.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingFive">
                <button
                  className="accordion-button collapsed text-black"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFive"
                  aria-expanded="false"
                  aria-controls="collapseFive"
                >
                  How is Network1 Coin (NET1) distributed?
                </button>
              </h2>
              <div
                id="collapseFive"
                className="accordion-collapse collapse"
                aria-labelledby="headingFive"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body text-black">
                  Network1 Coin (NET1) is distributed through various channels,
                  including airdrops, founder allocations, partner incentives,
                  node rewards, and mining rewards. Each distribution channel
                  serves to incentivize different stakeholders and promote
                  network growth.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingFive">
                <button
                  className="accordion-button collapsed text-black"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFive"
                  aria-expanded="false"
                  aria-controls="collapseFive"
                >
                  What is the utility of Network1 Coin (NET1) within the
                  Network1 Chain ecosystem?
                </button>
              </h2>
              <div
                id="collapseFive"
                className="accordion-collapse collapse"
                aria-labelledby="headingFive"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body text-black">
                  Network1 Coin (NET1) serves multiple purposes within the
                  Network1 Chain ecosystem, including transaction fees,
                  governance voting, staking rewards, participation in
                  decentralized applications (DApps), and access to premium
                  features and services offered by Network1 Chain.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingFive">
                <button
                  className="accordion-button collapsed text-black"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFive"
                  aria-expanded="false"
                  aria-controls="collapseFive"
                >
                  How can I acquire Network1 Coin (NET1)?
                </button>
              </h2>
              <div
                id="collapseFive"
                className="accordion-collapse collapse"
                aria-labelledby="headingFive"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body text-black">
                  Network1 Coin (NET1) can be acquired through various means,
                  including mining, staking, participating in network activities
                  and incentives, purchasing from cryptocurrency exchanges, and
                  receiving as rewards or incentives for contributing to the
                  network.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingFive">
                <button
                  className="accordion-button collapsed text-black"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFive"
                  aria-expanded="false"
                  aria-controls="collapseFive"
                >
                  What are the benefits of holding Network1 Coin (NET1)?
                </button>
              </h2>
              <div
                id="collapseFive"
                className="accordion-collapse collapse text-black"
                aria-labelledby="headingFive"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body text-black">
                  Holding Network1 Coin (NET1) offers several benefits, such as
                  access to network services and features, earning rewards
                  through staking or participation in network activities, voting
                  rights in governance decisions, and potential appreciation in
                  value over time as the network grows and matures.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingFive">
                <button
                  className="accordion-button collapsed text-black"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFive"
                  aria-expanded="false"
                  aria-controls="collapseFive"
                >
                  Is Network1 Coin (NET1) subject to inflation or deflationary
                  measures?
                </button>
              </h2>
              <div
                id="collapseFive"
                className="accordion-collapse collapse text-black"
                aria-labelledby="headingFive"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body text-black">
                  The inflationary or deflationary nature of Network1 Coin
                  (NET1) depends on various factors, including the tokenomics
                  model, supply dynamics, and network policies. Network1 Chain
                  may implement mechanisms to control token supply and adjust
                  inflation rates to maintain a stable and sustainable
                  ecosystem.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingFive">
                <button
                  className="accordion-button collapsed text-black"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFive"
                  aria-expanded="false"
                  aria-controls="collapseFive"
                >
                  How is the value of Network1 Coin (NET1) determined?
                </button>
              </h2>
              <div
                id="collapseFive"
                className="accordion-collapse collapse text-black"
                aria-labelledby="headingFive"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body text-black">
                  The value of Network1 Coin (NET1) is determined by market
                  demand and supply dynamics, investor sentiment, network
                  adoption and usage, technological advancements, regulatory
                  developments, and other external factors influencing the
                  cryptocurrency market.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
