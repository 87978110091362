import React from 'react'
import Header from '../Comman/Header'
import Footer from '../Comman/Footer'
import CompanyHero from './CompanyHero'
import Visionaries from './Visionaries'
import JoinUs from './JoinUs'
import Believe from './Believe'
import Roadmap from '../Comman/Roadmap'
import Tokenomics from '../Comman/Tokenomics'
import TelosFooter from '../Telos_net/TelosFooter'

export default function Company() {
    return (
        <>
            <Header />
            <CompanyHero />
            <Tokenomics />
            <Believe />
            {/* <Visionaries /> */}
            <Roadmap />
            <JoinUs />
            <TelosFooter/>
            {/* <Footer /> */}
        </>
    )
}
