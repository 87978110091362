import React from "react";
import Header from "../Comman/Header";
// import Footer from "../Comman/Footer";
// import Hero from "./Hero";
// import Advantage from "./Advantage";
// import Decentralization from "./Decentralization";
// import Allocation from "./Allocation";
// import Ecoosystem from "./Ecoosystem";
// import Advocates from "./Advocates";
// import Subscribe from "./Subscribe";
// import NetworkCommunity from "./NetworkCommunity";
// import Networker from "./Networker";
// import Tokenomics from "../Comman/Tokenomics";
// import Roadmap from "../Comman/Roadmap";
// import SocialMedia from "../Comman/SocialMedia";
import Heronet from "../Telos_net/Heronet";
import SNARKtor from "../Telos_net/SNARKtor";
import ReadyToExplore from "../Telos_net/ReadyToExplore";
import TelosFooter from "../Telos_net/TelosFooter";
import ProofofNetwork from "./ProofofNetwork";
import Infrastructure from "./Infrastructure";
import StartBuilding from "./StartBuilding";
import JoinUs from "./JoinUs";

export default function Index() {
  return (
    <div
      class="boxed_wrapper ltr"
      style={{ overflow: "hidden", backgroundColor: "black" }}
    >
      <Header />
      <Heronet />
      <SNARKtor />
      <ProofofNetwork />
      <Infrastructure />
      <StartBuilding />
      <JoinUs />
      <ReadyToExplore />
      <TelosFooter />
      {/*  */}
      {/* <Hero /> */}
      {/* <Web3 /> */}
      {/* <Advantage /> */}
      {/* <Decentralization /> */}
      {/* <Tokenomics /> */}
      {/* <Allocation /> */}
      {/* <NetworkCommunity /> */}
      {/* <Roadmap /> */}
      {/* <Ecoosystem /> */}
      {/* <Advocates /> */}
      {/* <Networker /> */}
      {/* <SocialMedia/> */}
      {/* <Subscribe /> */}
      {/* <Footer /> */}
    </div>
  );
}
