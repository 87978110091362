import React from "react";

function WhyuseNUSD() {
  return (
    <div>
      <div
        className="fugu--content-section bg-white pt-5"
        style={{
          // backgroundImage: `url('assets/images/img-one/n1_network-bg.png')`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "50% 50%",
        }}
      >
        <div className="container">
          <div className="fugu--content-top">
            <div className="row">
              <div className="col-xl-6 d-flex justify-content-center align-items-center order-xl-2">
                <div className="fugu--content-thumb">
                  <img
                    className="wow fadeInUpX w-100"
                    data-wow-delay=".10s"
                    src="assets/images/img-one/Why-use-NUSD.png"
                    alt=""
                    style={{
                      visibility: "visible",
                      animationDelay: "0.1s",
                      animationName: "fadeInUpX",
                    }}
                  />
                </div>
              </div>
              <div className="col-xl-6">
                <div className="fugu--default-content">
                  <h2 className="text-black">Why use USDN? </h2>
                  <div className="fugu--meta">
                    <ul>
                      <li className="text-black">
                        <img src="assets/images/svg2/check.svg" alt="" />
                        Using NUSUSDN offers several advantages, particularly
                        within the Network1 blockchain ecosystem:
                      </li>
                      <li className="text-black">
                        <img src="assets/images/svg2/check.svg" alt="" />
                        NUSUSDN is pegged to the US dollar, ensuring that its value
                        remains consistent at $1. This stability makes it ideal
                        for everyday transactions, protecting users from the
                        volatility typically associated with cryptocurrencies.
                      </li>
                      <li className="text-black">
                        <img src="assets/images/svg2/check.svg" alt="" />
                        It operates on a decentralized network, meaning it isn't
                        controlled by any single entity. This decentralization
                        enhances security and transparency, giving users
                        confidence in the currency's integrity and reliability.
                      </li>
                      <li className="text-black">
                        <img src="assets/images/svg2/check.svg" alt="" />
                        As a stablecoin specifically designed for the Network1
                        blockchain, NUSUSDN allows for smooth and efficient
                        transactions within the ecosystem. Whether you're making
                        payments, transferring value, or engaging in other
                        financial activities, NUSUSDN ensures quick and
                        cost-effective operations.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WhyuseNUSD;
