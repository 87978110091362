import React from "react";

export default function GrantsHero() {
  return (
    <>
      <div
        className="fugu--about-hero-section dark-version vh-100"
        style={{
          backgroundImage: `url('assets/images/img-one/network-studio-bg.jpg')`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "50% 50%",
        }}
      >
        <div className="container ">
          <div className="row mt-lg-0 mt-5">
            <div className="col-lg-7 col-md-10 col-sm-12 col-12">
              <h1 className="text-white text-center">Network1 Studio</h1>
              <p className="text-white text-center">
                A dApp accelerator platform designed to empower developers by
                providing the resources, tools, and guidance necessary to build,
                grow, and scale their projects within the Network1 ecosystem.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
