import React from 'react'
import Header from '../Comman/Header'
import Footer from '../Comman/Footer'
import NetMeetHero from './NetMeetHero'
import NetMeetFeature from './NetMeetFeature'
import WhyChoosNetMeet from './WhyChoosNetMeet'
import TelosFooter from '../Telos_net/TelosFooter'

export default function NetMeet() {
    return (
        <>
            <Header />
            <NetMeetHero />
            <NetMeetFeature /> 
            <WhyChoosNetMeet />
            <TelosFooter/>
            {/* <Footer /> */}
        </>
    )
}
