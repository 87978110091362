import React from "react";

export default function Tokenomics() {
  return (
    <>
      <div className="fugu--vision-section bg-white py-5">
        <div className="container">
          <div className="row py-5 align-items-center">
            <div className="col-xl-6 col-lg-6 d-flex align-items-center">
              <div className="fugu--default-content content-sm">
                <h2 className="text-black">Token Details</h2>
                <ul>
                  <li>
                    <h4 className="text-black">
                      Name: <span className="fs-5"> Network1 Coin</span>
                    </h4>
                  </li>
                  <li>
                    <h4 className="text-black">
                      Ticker Symbol:<span className="fs-5"> NET1</span>
                    </h4>
                  </li>
                  <li>
                    <h4 className="text-black">
                      Total Supply:{" "}
                      <span className="fs-5">2,21,00,000 NET1</span>
                    </h4>
                  </li>
                  <li>
                    <h4 className="text-black">
                      Decimal: <span className="fs-5"> 18</span>
                    </h4>
                  </li>
                  <li>
                    <h4 className="text-black">
                      Contract Adderss:{" "}
                      <span className="fs-5">
                        <a
                          href="https://bscscan.com/address/0x102054c91c93b0d30f982f28f224896d3d844b99"
                          target="_blank"
                        >
                          0x102....44b99
                        </a>
                      </span>
                    </h4>
                  </li>
                </ul>
                <div className="fugu--btn-wrap">
                  <a
                    className="fugu--btn bg-blue"
                    href="https://pancakeswap.finance/swap?inputCurrency=0x55d398326f99059fF775485246999027B3197955&outputCurrency=0x102054C91C93B0D30f982f28f224896D3d844b99"
                    target="_blnak"
                  >
                    Buy Token
                  </a>
                </div>
              </div>
            </div>
            <div className=" col-xl-6 col-lg-6 ">
              <img
                src="assets/images/img-one/tokenomics.png"
                className="w-100"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
