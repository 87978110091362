import React from "react";

export default function Stepstoapply() {
  return (
    <>
      <div className="section bg-warning-200 fugu-section-padding3">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="py-4 text-start">
                <h2>Contribute to the Network1 Ecosystem </h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-10 col-md-12">
              <div
                className="box-style fugu-iconbox-wrap2 wow fadeInUpX"
                data-wow-delay="0s"
                style={{
                  visibility: "visible",
                  animationDelay: "0s",
                  animationName: "fadeInUpX",
                }}
              >
                <div className="row">
                  <div className="col-lg-8 col-md-12 ">
                    <div className="fugu-iconbox-data2">
                      <h4>Join Community Programs</h4>
                      <p>
                        Get together with our community of developers & learn
                        more about Network1! Explore all you need to know to get
                        started.
                      </p>
                      <h4 className="text-white">
                        <span className="fs-5"></span>
                        <a
                          className="arrow-button w-inline-block"
                          href="/coming-soon"
                        >
                          View Programs
                          <span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              fill="currentColor"
                              class="bi bi-arrow-right"
                              viewBox="0 0 16 16"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"
                              />
                            </svg>
                          </span>
                        </a>
                      </h4>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-12">
                    <div className="fugu-iconbox-icon2">
                      <img
                        src="assets/images/img-one/Study-the-NET.png"
                        width={300}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-10 col-md-12">
              <div
                className="box-style fugu-iconbox-wrap2 wow fadeInUpX"
                data-wow-delay="0s"
                style={{
                  visibility: "visible",
                  animationDelay: "0s",
                  animationName: "fadeInUpX",
                }}
              >
                <div className="row">
                  <div className="col-lg-8 col-md-12">
                    <div className="fugu-iconbox-data2">
                      <h4>Claim NET1 Grants </h4>
                      <p>
                        Apply to claim Grants for your Network1 dApps &
                        accelerate development with our support, strategies &
                        combined efforts!
                      </p>
                      <h4 className="text-white">
                        <span className="fs-5"></span>
                        {/* <a href="/coming-soon" target="_blank">
                          Ecosystem map
                        </a> */}
                        <a
                          className="arrow-button w-inline-block"
                          href="/coming-soon"
                        >
                          Apply for Grants
                          <span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              fill="currentColor"
                              class="bi bi-arrow-right"
                              viewBox="0 0 16 16"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"
                              />
                            </svg>
                          </span>
                        </a>
                      </h4>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-12">
                    <div className="fugu-iconbox-icon2">
                      <img
                        src="assets/images/img-one/Explore-the-NET.png"
                        width={300}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-10 col-md-12">
              <div
                className="box-style fugu-iconbox-wrap2 wow fadeInUpX"
                data-wow-delay="0s"
                style={{
                  visibility: "visible",
                  animationDelay: "0s",
                  animationName: "fadeInUpX",
                }}
              >
                <div className="row">
                  <div className="col-lg-12 col-md-12">
                    <div className="fugu-iconbox-data2">
                      <h4>Submit your dApp for SDC ecosystem!</h4>
                      <div className="fugu--btn-wrap">
                        <a
                          className="fugu--btn bg-blue rounded-pill"
                          href="/coming-soon"
                        >
                          Submit
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
