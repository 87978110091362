import React from "react";

export default function ProofofNetwork() {
  return (
    <>
      <div
        className="py-5 my-md-5"
        style={{
          backgroundImage: "url(assets/images/img-one/bg.png)",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "50% 50%",
        }}
      >
        <div className="container">
          <div className="row align-items-center py-5 my-md-5">
            <div className="col-xl-7 ">
              <img
                style={{ width: "80%" }}
                src="assets/images/img-one/proof-of-network-pom.png"
              />
            </div>
            <div className="col-xl-5">
              <p className="text-color-zk text-style-allcaps">Get to know</p>
              <h2 className="text-white">Proof-of-Network (PoN)</h2>
              <p className="text-white">
                Proof of Network (PoN) is an innovative consensus mechanism
                designed to secure and validate transactions on a blockchain
                network. Unlike traditional consensus mechanisms like Proof of
                Work (PoW) or Proof of Stake (PoS), which rely on computational
                power or token ownership, PoN leverages the concept of network
                participation and reputation to achieve consensus.
              </p>
              <div className="divider"></div>
              <ul className="text-white">
                <li>
                  <i
                    style={{ color: "#f39f1b" }}
                    class="fa fa-circle"
                    aria-hidden="true"
                  ></i>
                  <span className="ps-3" style={{ fontSize: "20px" }}>
                    Networking-focused
                  </span>
                </li>
                <li>
                  <i
                    style={{ color: "#f39f1b" }}
                    class="fa fa-circle"
                    aria-hidden="true"
                  ></i>
                  <span className="ps-3" style={{ fontSize: "20px" }}>
                    Peer-to-peer Nodes
                  </span>
                </li>
                <li>
                  <i
                    style={{ color: "#f39f1b" }}
                    class="fa fa-circle"
                    aria-hidden="true"
                  ></i>
                  <span className="ps-3" style={{ fontSize: "20px" }}>
                    Security of PoS Consensus
                  </span>
                </li>
              </ul>
              <br />
              {/* <div className="button-group">
              <a className="button-Whitepaper">
                <button className="w-button " href="">
                  Learn more
                </button>
              </a>
            </div> */}
              <a className="fugu--btn bg-blue active rounded-pill">
                Learn more
              </a>
              <br />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
