import React from "react";

export default function WhyChoosNetFx() {
  return (
    <>
      <div
        className="fugu--content-section bg-white pt-5"
        style={{
          backgroundImage: `url('assets/images/img-one/n1_network-bg.png')`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "50% 50%",
        }}
      >
        <div className="container">
          <div className="fugu--content-top">
            <div className="row">
              <div className="col-xl-6 d-flex justify-content-center align-items-center order-xl-2">
                <div className="fugu--content-thumb">
                  <img
                    className="wow fadeInUpX w-100"
                    data-wow-delay=".10s"
                    src="assets/images/img-one/why-choose-us.(2).png"
                    alt=""
                    style={{
                      visibility: "visible",
                      animationDelay: "0.1s",
                      animationName: "fadeInUpX",
                    }}
                  />
                </div>
              </div>
              <div className="col-xl-6">
                <div className="fugu--default-content">
                  <h2 className="">Why Choose Net1Ex?</h2>
                  <div className="fugu--meta">
                    <ul>
                      <li className="">
                        <img src="assets/images/svg2/check.svg" alt="" />
                        Transparency: NET1FX provides full transparency into
                        trading activities, with all transactions recorded on
                        the blockchain ledger. This ensures trust and integrity
                        in every trade.
                      </li>
                      <li className="">
                        <img src="assets/images/svg2/check.svg" alt="" />
                        Security: Built on secure blockchain technology, NET1FX
                        offers robust security measures to protect traders'
                        assets and personal information from unauthorized access
                        or tampering.
                      </li>
                      <li className="">
                        <img src="assets/images/svg2/check.svg" alt="" />
                        Efficiency: By automating trade execution and settlement
                        through smart contracts, NET1FX streamlines the trading
                        process, reducing delays and improving overall
                        efficiency.
                      </li>
                      <li className="">
                        <img src="assets/images/svg2/check.svg" alt="" />
                        Cost Savings: With its decentralized architecture,
                        NET1FX eliminates the need for intermediaries such as
                        banks or brokers, resulting in lower transaction fees
                        and cost savings for traders.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
