import React from "react";

function StablecoinHero() {
  return (
    <div
      className="fugu--inner-section dark-version"
      style={{
        // backgroundImage: `url('assets/images/img-one/n1_network-bg.png')`,
        backgroundImage: `url('https://cdn.prod.website-files.com/650f276b38fe3eaebe9f805a/66877d8cf33b7791b37432bc_evm-image-5.webp')`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "50% 50%",
      }}
    >
      <div className="container">
        <div className="fugu--breadcrumbs-section">
          <div className="fugu--breadcrumbs-dat center-content text-start">
            <h2 className="text-white">
              Better Cryptocurrency Payments For Network1 Ecosystem
            </h2>
            <p className="text-white">
              Pay USDN Stablecoin anytime, anywhere, to anyone.
            </p>
            <div className="fugu--btn-wrap  fugu--hero-btn">
              <a className="fugu--btn bg-gradient" href="">
                Get USDN
              </a>
              <a className="fugu--btn bg-gradient" href="">
                Contract
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StablecoinHero;
