import React from "react";
import Header from "../Comman/Header";
import Footer from "../Comman/Footer";
import NetPlayHero from "./NetPlayHero";
import NetPlayFeature from "./NetPlayFeature";
import WhyChoosNetPlay from "./WhyChoosNetPlay";
import TelosFooter from "../Telos_net/TelosFooter";

export default function NetPlay() {
  return (
    <>
      <Header />
      <NetPlayHero />
      <NetPlayFeature />
      <WhyChoosNetPlay />
      <TelosFooter />
      {/* <Footer /> */}
    </>
  );
}
