import React from "react";

export default function DeveloperHero() {
  return (
    <>
      <div
        className="fugu--hero-section3 py_150"
        style={{
          backgroundImage: `url('assets/images/img-one/build-on-network1.jpg')`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "50% 50%",
          backgroundColor: "#f9f9f9",
        }}
      >
        <div className="container">
          <div className="row justify-content-center pb-md-5 mt-md-5">
            <div className="col-lg-9 pb-5">
              <div className="fugu--hero-conten fugu--hero-content3">
                <h1
                  className="wow fadeInUpX text-white"
                  data-wow-delay="0s"
                  style={{
                    visibility: "visible",
                    animationDelay: "0s",
                    animationName: "fadeInUpX",
                  }}
                >
                  Build on Network1
                </h1>
                <div
                  className="fugu--btn-wrap fugu--hero-btn wow fadeInUpX"
                  data-wow-delay="0.25s"
                  style={{
                    visibility: "visible",
                    animationDelay: "0.25s",
                    animationName: "fadeInUpX",
                  }}
                >
                  <a
                    className="fugu--btn bg-blue active"
                    href="https://networkchain.gitbook.io/developer-documentation"
                    target="_blank"
                  >
                    BUILD
                  </a>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="row justify-content-center">
            <div className="col-lg-9">
              <div
                className="video-text"
                style={{
                  visibility: "visible",
                  animationName: "fadeInUpX",
                  height: "500px",
                }}
              >
                <div className="pt-5">
                  <p
                    className="wow fadeInUpX text-white text-start"
                    data-wow-delay="0.15s"
                    style={{
                      visibility: "visible",
                      animationDelay: "0.15s",
                      animationName: "fadeInUpX",
                    }}
                  >
                    Start developing seamless dApps on Network1
                  </p>
                </div>
              </div>
              <video muted autoPlay loop id="myVideo" className="video-2">
                <source
                  src="assets/images/img-one/n1_hero_banner.mp4"
                  type="video/mp4"
                />
              </video>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
}
