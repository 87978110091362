import React from "react";

export default function QAHero() {
  return (
    <>
      <div
        className="fugu--hero-section3"
        style={{
        //   backgroundImage: `url('assets/images/img-one/n1_network-bg.png')`,
        backgroundImage:'url(https://cdn.prod.website-files.com/650f276b38fe3eaebe9f805a/6687d16a8448e732075afabc_zero-image-1.webp)',
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "50% 50%",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-7">
              <div className="fugu--hero-content fugu--hero-content3">
                <h1
                  className="wow fadeInUpX"
                  data-wow-delay="0s"
                  style={{
                    visibility: "visible",
                    animationDelay: "0s",
                    animationName: "fadeInUpX",
                  }}
                >
                  Building Networks, Changing Lives.
                </h1>
                <p
                  className="wow fadeInUpX"
                  data-wow-delay="0.15s"
                  style={{
                    visibility: "visible",
                    animationDelay: "0.15s",
                    animationName: "fadeInUpX",
                  }}
                >
                  Get started with one of the easiest and most secure platforms
                  for buying, selling, trading, and earning cryptocurrency in
                  one place.
                </p>
                <div
                  className="fugu--btn-wrap fugu--hero-btn wow fadeInUpX"
                  data-wow-delay="0.25s"
                  style={{
                    visibility: "visible",
                    animationDelay: "0.25s",
                    animationName: "fadeInUpX",
                  }}
                >
                  <a
                    className="fugu--btn bg-blue active"
                    href="https://networkchain.gitbook.io/developer-documentation/"
                    target="_blnak"
                  >
                    Documentation
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="fugu--thumb-three">
                <div className="fugu--hero-thumb fugu--hero-thumb3">
                  <img
                    className="wow fadeInUpX w-100"
                    data-wow-delay="0.20s"
                    src="assets/images/img-one/Building-Networks-Changing.png"
                    alt=""
                    style={{
                      visibility: "visible",
                      animationDelay: "0.2s",
                      animationName: "fadeInUpX",
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
