import React from "react";

function FinancialFreedom() {
  return (
    <>
     <div className="section bg-warning-200 fugu-section-padding3">
        <div className="container" >
          <div className="row" style={{justifyContent:"center"}}>
            <div className="col-lg-10 col-md-12">
              <div
                className="box-style fugu-iconbox-wrap2 wow fadeInUpX"
                data-wow-delay="0s"
                style={{
                  visibility: "visible",
                  animationDelay: "0s",
                  animationName: "fadeInUpX",
                }}
              >
                <div className="row">
                  <div className="col-lg-8 col-md-12">
                    <div className="fugu-iconbox-data2" style={{paddingTop:"10%"}}>
                    <h4>Mint USND Stablecoin from Network1 App</h4>
                      <p>Go to Network1 App & mint USDN stablecoin against your NET1 coins.</p>
                      <h4 className="text-white">
                        <span className="fs-5"></span>
                        <a href="#" >
                        Mint Now
                        </a>
                      </h4>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-12">
                    <div className="fugu-iconbox-icon2">
                      <img
                        src="assets/images/img-one/Mint-NUSD-Stablecoin-from-Network1-App.png"
                        width={300}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row" style={{justifyContent:"center"}}>
            <div className="col-lg-10 col-md-12">
              <div
                className="box-style fugu-iconbox-wrap2 wow fadeInUpX"
                data-wow-delay="0s"
                style={{
                  visibility: "visible",
                  animationDelay: "0s",
                  animationName: "fadeInUpX",
                }}
              >
                <div className="row">
                  <div className="col-lg-8 col-md-12">
                    <div className="fugu-iconbox-data2" style={{paddingTop:"10%"}}>
                    <h4>Learn More About USDN</h4>
                      <p>Find out more details about USDN stablecoin from documentation. </p>
                      <h4 className="text-white">
                        <span className="fs-5"></span>
                        <a href="#" >
                        Learn more
                        </a>
                      </h4>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-12">
                    <div className="fugu-iconbox-icon2">
                      <img
                        src="assets/images/img-one/learn-more-about-nusd.png"
                        width={300}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default FinancialFreedom;
