import React from "react";

export default function CEGHero() {
  return (
    <>
      <div
        className="fugu--hero-section3 bg-white"
        style={{
          backgroundImage: `url('assets/images/img-one/n1_network-bg.png')`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "50% 50%",
        }}
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-9">
              {/* <div
                                className="video-text"
                                style={{
                                    visibility: "visible",
                                    animationName: "fadeInUpX",
                                    height: "500px",
                                }}
                            > */}

              <div className="fugu--hero-conten fugu--hero-content3">
                <h1 className="text-white text-style-allcaps">
                  Use NET1 & Govern Network1 Ecosystem
                </h1>
                {/* <p className="text-color-zero text-style-allcaps">Announcing</p>
                <h5
                  className="wow fadeInUpX text-color-zero text-style-allcaps"
                  data-wow-delay="0s"
                  style={{
                    visibility: "visible",
                    animationDelay: "0s",
                    animationName: "fadeInUpX",
                  }}
                >
                  NCT
                </h5>
                <h3 className="text-color-zero text-style-allcaps">
                  Community Ecosystem <br /> Governance
                </h3> */}
              </div>
              <p
                // className="wow fadeInUpX text-white text-start fw-bold fs-5"
                className="text-white text-style-allcaps fw-bold fs-5"
                data-wow-delay="0.15s"
                style={{
                  visibility: "visible",
                  animationDelay: "0.15s",
                  animationName: "fadeInUpX",
                }}
              >
                NET1 enables its holders to participate in the ecosystem
                governance.
              </p>
              <a
                className="fugu--btn bg-blue rounded-pill active"
                href="/coming-soon"
              >
                Vote Now
              </a>
              {/* </div> */}
              {/* <video muted autoPlay loop id="myVideo" className="video-2 d-lg-block d-md-block d-sm-none d-none">
                                <source src="assets/images/img-one/governance_hero.mp4" type="video/mp4" />
                            </video>
                            <video muted autoPlay loop id="myVideo" className="video-2 d-lg-none d-md-none d-sm-block d-block">
                                <source src="assets/images/img-one/governance_hero_mobile.mp4" type="video/mp4" />
                            </video> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
