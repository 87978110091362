import React from "react";

export default function NetterFeature() {
  return (
    <>
      <div className="fugu--content-section">
        <div className="container">
          <div className="fugu--content-bottom">
            <div className="row align-items-center pb-5">
              <div className="col-xl-6 d-flex align-items-center">
                <div className="fugu--content-thumb">
                  <img
                    className="wow fadeInLeft"
                    data-wow-delay=".10s"
                    src="assets/images/img-one/Features-of-NetEx.png"
                    alt=""
                    style={{
                      visibility: "visible",
                      animationDelay: "0.1s",
                      animationName: "fadeInLeft",
                    }}
                  />
                </div>
              </div>
              <div className="col-xl-6">
                <div className="fugu--default-content">
                  <h2 className="">Features of Netter</h2>
                  <div
                    className="fugu--accordion-one accordion-two"
                    id="accordionExample"
                  >
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingOne">
                        <button
                          className="accordion-button collapsed "
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOne"
                          aria-expanded="true"
                          aria-controls="collapseOne"
                        >
                          Networking Opportunities:
                        </button>
                      </h2>
                      <div
                        id="collapseOne"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingOne"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body ">
                          Netter provides users with a platform to expand their
                          professional network and connect with industry peers,
                          mentors, and potential collaborators. Whether you're a
                          seasoned networker or just starting out, Netter offers
                          valuable networking opportunities to help you grow
                          your circle.
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingTwo">
                        <button
                          className="accordion-button collapsed "
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseTwo"
                          aria-expanded="false"
                          aria-controls="collapseTwo"
                        >
                          Knowledge Sharing
                        </button>
                      </h2>
                      <div
                        id="collapseTwo"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingTwo"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body ">
                          With Netter's intuitive interface and multimedia
                          capabilities, users can easily share valuable
                          insights, industry updates, and educational content
                          with their network. Whether it's through text posts,
                          images, videos, or live streams, Netter empowers users
                          to contribute to meaningful conversations and share
                          their expertise.
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingThree">
                        <button
                          className="accordion-button collapsed "
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseThree"
                          aria-expanded="false"
                          aria-controls="collapseThree"
                        >
                          Community Engagement
                        </button>
                      </h2>
                      <div
                        id="collapseThree"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingThree"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body ">
                          Netter fosters a sense of community and belonging
                          among its users through interactive features such as
                          comments, likes, shares, and group discussions. Users
                          can engage with each other's posts, join specialized
                          groups, and participate in virtual events and meetups
                          organized within the platform.
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingFour">
                        <button
                          className="accordion-button collapsed "
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseFour"
                          aria-expanded="false"
                          aria-controls="collapseFour"
                        >
                          Content Discovery
                        </button>
                      </h2>
                      <div
                        id="collapseFour"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingFour"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body ">
                          Netter's powerful content discovery algorithms and
                          personalized recommendations help users discover
                          relevant content and connect with users who share
                          similar interests and expertise. Whether you're
                          looking for industry insights, professional advice, or
                          networking opportunities, Netter makes it easy to find
                          what you're looking for.
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingFive">
                        <button
                          className="accordion-button collapsed "
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseFive"
                          aria-expanded="false"
                          aria-controls="collapseFive"
                        >
                          Privacy and Security
                        </button>
                      </h2>
                      <div
                        id="collapseFive"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingFive"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body ">
                          Netter prioritizes user privacy and security,
                          implementing robust data protection measures and
                          privacy controls to ensure that users feel safe and
                          confident in sharing their information and engaging
                          with the platform.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
