import React from "react";

export default function GetStarted() {
  return (
    <>
      <div className="fugu-cta-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="fugu-default-content large-content">
                <h2 className="">Get Started with NET1FX</h2>
                <h5>
                  Ready to experience the benefits of NET1FX for yourself? Join
                  our platform today and take your forex trading to the next
                  level. With NET1FX, you'll gain access to a secure,
                  transparent, and efficient trading environment that's
                  revolutionizing the forex industry. Join us and be a part of
                  the future of forex trading!
                </h5>
              </div>
            </div>
            <div className="col-lg-4 d-flex align-items-center">
              <div className="fugu-cta-app-btn-wrap">
                <a className="fugu--btn bg-blue active" href="/coming-soon">
                  Visit
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
