import React from "react";

export default function Whitepaper() {
  return (
    <>
      <div className="fugu-cta-section" style={{ backgroundColor: "#f9f9f9" }}>
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="fugu-default-content large-content">
                <h2 className="">
                  Learn more to begin as a Network1 developer
                </h2>
              </div>
            </div>
            <div className="col-lg-4 d-flex align-items-center">
              <div className="fugu-cta-app-btn-wrap">
                <a
                  className="fugu--btn bg-blue active"
                  href="https://networkchain.gitbook.io/networkchain"
                  target="_blank"
                >
                  Docs
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
