import React from "react";

function SNARKtor() {
  return (
    <>
      <div className="container py-5 my-md-5">
        <div
          className="heading-style-h2 text-white "
          style={{ textAlign: "center" }}
        >
          Proposing
          <span style={{ color: "#f39f1b" }}> the native blockchain</span> for
          accelerating & expanding
          <span style={{ color: "#f39f1b" }}> the Network1 Marketing</span>
          industry
        </div>
        <br />
        <br />
        <div className="row">
          <div className="col-xl-6">
            {/* <p>Get to know </p> */}
            <h2 className="text-white">Network1</h2>
            <p className="text-white">
              Network1 is designed to provide a robust, scalable, and efficient
              platform for network marketing operations. It recognizes the
              inherent complexities and limitations that network marketing firms
              encounter, including difficulties in global expansion, lack of
              transparency, and cumbersome payment processes.
            </p>
            <div className="divider"></div>
            <ul className="text-white" style={{ listStyle: "circle" }}>
              <li>
                <i
                  style={{ color: "#f39f1b" }}
                  class="fa fa-circle"
                  aria-hidden="true"
                ></i>
                <span className="ps-3" style={{ fontSize: "20px" }}>
                  High throughput
                </span>
              </li>
              <li>
                <i
                  style={{ color: "#f39f1b" }}
                  class="fa fa-circle"
                  aria-hidden="true"
                ></i>
                <span className="ps-3" style={{ fontSize: "20px" }}>
                  Cost efficient
                </span>
              </li>
              <li>
                <i
                  style={{ color: "#f39f1b" }}
                  class="fa fa-circle"
                  aria-hidden="true"
                ></i>
                <span className="ps-3" style={{ fontSize: "20px" }}>
                  Robust Security
                </span>
              </li>
              <li>
                <i
                  style={{ color: "#f39f1b" }}
                  class="fa fa-circle"
                  aria-hidden="true"
                ></i>
                <span className="ps-3" style={{ fontSize: "20px" }}>
                  Liner Scalable
                </span>
              </li>
            </ul>
            <br />
            {/* <div className="button-group">
              <a className="button-Whitepaper">
                <button className="w-button " href="/">
                  Read More
                </button>
              </a>
            </div> */}
            <a className="fugu--btn bg-blue active rounded-pill">Learn more</a>
            <br />
          </div>
          <div className="col-xl-6 d-flex justify-content-center align-items-centerol">
            <img
              style={{ width: "90%" }}
              src="assets/images/img-one/Network1.png"
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default SNARKtor;
