import React from "react";

function UsingNUSD() {
  return (
    <>
      <div className="section fugu-section-padding3">
        <div className="container">
          <div className="">
            <div className="row">
              <div className="col-xl-6">
                <div className="" style={{ marginTop: "25%" }}>
                  <h2><small>Access Worldwide Digital Payments Using USDN</small></h2>
                  <p><big>
                  USDN is a currency that is decentralized and pegs to the
                    value of $1. Any individual or business can use it for
                    payments, transactions and other means of value transfers.
                  </big>
                  </p>
                </div>
              </div>
              <div className="col-xl-6 d-flex justify-content-center align-items-center">
                <div className="">
                  <img
                    className="wow fadeInUpX"
                    data-wow-delay=".10s"
                    src="assets/images/img-one/access-worldwide-digital.png"
                    width="100%"
                    alt=""
                    style={{
                      visibility: "visible",
                      animationDelay: "0.1s",
                      animationName: "fadeInUpX",
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default UsingNUSD;
