import React from "react";

export default function NetPayHero() {
  return (
    <>
      <div
        className="fugu--hero-section bg-white"
        style={{
          backgroundImage: `url('assets/images/img-one/n1_network-bg.png')`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "50% 50%",
        }}
      >
        <div id="fugu--counter" />
        <div className="container">
          <div className="row">
            <div className="col-lg-7">
              <div className="fugu--hero-content">
                <h1 className="wow fadeInUpX" data-wow-delay="0s">
                  Streamlining Payments with Network1 Chain
                </h1>
                <p className="wow fadeInUpX" data-wow-delay="0.10s">
                  NetPay is a cutting-edge payment solution built on the
                  Network1 Chain blockchain network, designed to simplify
                  transactions and empower users with secure, efficient, and
                  borderless payment capabilities. With its innovative features
                  and user-friendly interface, NetPay is revolutionizing the way
                  people transact and manage their finances.
                </p>
                <div
                  className="fugu--btn-wrap fugu--hero-btn wow fadeInUpX"
                  data-wow-delay="0.20s"
                >
                  <a className="fugu--btn bg-gray active" href="/coming-soon">
                    Visit
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-5">
              <img
                src="assets/images/img-one/Net-Pay-Phn.(2).png"
                className="w-100"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
