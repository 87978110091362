import React from "react";

export default function CommunityHero() {
  return (
    <>
      <div
        className="fugu--hero-section3 bg-white"
        style={{
        //   backgroundImage: "url(assets/images/all-img/v5/hero-bg2.png)",
        }}
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-9">
              {/* <div
                className="video-text"
                style={{
                  visibility: "visible",
                  animationName: "fadeInUpX",
                  height: "500px",
                }}
              > */}
                <div className="pt-5">
                  <h1
                    className="span1"
                    data-wow-delay="0s"
                    style={{
                      visibility: "visible",
                      animationDelay: "0s",
                      animationName: "fadeInUpX",
                    }}
                  >
                    Apps.
                    Businesses.
                    Communities.
                  </h1>
                </div>
              {/* </div> */}
              {/* <video muted autoPlay loop id="myVideo" className="video-2">
                <source
                  src="assets/images/img-one/n1_hero_banner.mp4"
                  type="video/mp4"
                />
              </video> */}
            </div>
          </div>
        </div>
        <div className="fugu--circle-shape circle-one">
          <img src="assets/images/all-img/shapes-round.png" alt="" />
          <div className="waves wave-1" />
        </div>
        <div className="fugu--circle-shape circle-two">
          <img src="assets/images/all-img/shapes-round.png" alt="" />
          <div className="waves wave-1" />
        </div>
      </div>
    </>
  );
}
