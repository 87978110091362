import React from "react";

export default function Allocation() {
  return (
    <>
      <div className="section fugu-section-padding3 bg-black">
        <div className="container">
          <div className="fugu-gallery-wrap3">
            <div className="row">
              <div className="col-lg-12">
                <div className="fugu-gallery-data4 text-center">
                  <h2 className="text-white">
                    Network1 Studio has accelerated 10+ dApps
                  </h2>
                  <p className="text-white">
                    We have already been instrumental in the success of several
                    dApps within the Network1 ecosystem. From gaming platforms
                    to DeFi solutions, projects accelerated by Network1 Studio
                    have demonstrated significant growth and impact.
                  </p>
                </div>
              </div>
              <div className="col-lg-12">
                <div
                  className="fugu-gallery-thumb wow fadeInUpX"
                  style={{ visibility: "visible", animationName: "fadeInUpX" }}
                >
                  <img
                    src="assets/images/img-one/n1-network-tracker.png"
                    className="w-100"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
