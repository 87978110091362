import React from "react";

export default function NetPlay() {
  return (
    <>
      <div className="fugu--content-section  pt-5">
        <div className="container">
          <div className="fugu--content-top">
            <div className="row align-items-center">
              <div className="col-xl-6 d-flex justify-content-center align-items-center order-xl-2">
                <img
                  src="assets/images/img-one/netplay.svg"
                  className="w-100"
                  alt=""
                />
              </div>
              <div className="col-xl-6">
                <div className="fugu--default-content pb-5">
                  <h2 className=" text-style-allcaps">Netplay</h2>
                  <p className="">
                    NetPlay is an innovative gaming platform integrated into the
                    Network1 Chain ecosystem, offering users a seamless gaming
                    experience. With a diverse range of games and interactive
                    features, NetPlay provides entertainment while fostering
                    community engagement. Users can enjoy multiplayer games,
                    tournaments, and rewards, enhancing their gaming experience
                    within the Network1 Chain ecosystem.
                  </p>
                </div>
                <div className="button-Whitepaper-w30">
                  <a className="w-button" href="/net-play">
                    Learn more
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
