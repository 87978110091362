import React from 'react'
import Header from '../Comman/Header'
import Footer from '../Comman/Footer'
import NetPayHero from './NetPayHero'
import NetPayFeature from './NetPayFeature'
import WhyChoosNetPay from './WhyChoosNetPay'
import TelosFooter from '../Telos_net/TelosFooter'

export default function NetPay() {
    return (
        <>
            <Header />
            <NetPayHero />
            <NetPayFeature /> 
            <WhyChoosNetPay />
            <TelosFooter/>
            {/* <Footer /> */}
        </>
    )
}
