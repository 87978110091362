import React from "react";

export default function ProductHero() {
  return (
    <>
      {/* <div className="fugu--about-hero-section dark-version" style={{backgroundImage:`url('assets/images/network-bg.png')`, backgroundRepeat:"no-repeat", backgroundSize:"cover",backgroundPosition:"50% 50%"}}>
        <div className="container">
          <div className="fugu--breadcrumb-thumb-top">
            <div className="fugu--breadcrumb-thumb thumb1">
              <img src="assets/images/all-img/about2/06.png" alt="" />
            </div>
            <div className="fugu--breadcrumb-thumb thumb2">
              <img src="assets/images/all-img/about2/07.png" alt="" />
            </div>
            <div className="fugu--breadcrumb-thumb thumb3">
              <img src="assets/images/all-img/about2/08.png" alt="" />
            </div>
            <div className="fugu--breadcrumb-thumb thumb4">
              <img src="assets/images/all-img/about2/09.png" alt="" />
            </div>
          </div>
          <div className="fugu--breadcrumb-thumb-bottom">
            <div className="fugu--breadcrumb-thumb thumb2">
              <img src="assets/images/all-img/about2/01.png" alt="" />
            </div>
            <div className="fugu--breadcrumb-thumb thumb3">
              <img src="assets/images/all-img/about2/02.png" alt="" />
            </div>
            <div className="fugu--breadcrumb-thumb thumb4">
              <img src="assets/images/all-img/about2/03.png" alt="" />
            </div>
            <div className="fugu--breadcrumb-thumb thumb2">
              <img src="assets/images/all-img/about2/04.png" alt="" />
            </div>
            <div className="fugu--breadcrumb-thumb thumb3">
              <img src="assets/images/all-img/about2/05.png" alt="" />
            </div>
          </div>
        </div>
      </div> */}
      <div
        className="fugu--about-section2  pt-5"
        style={{
          backgroundImage: `url('assets/images/img-one/network1-ecosystem-bg.jpg')`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "50% 50%",
        }}
      >
        <div className="container pb-5">
          <div className="fugu--section-title">
            <div
              className="fugu--hero-content mt-5"
              style={{ paddingTop: "150px" }}
            >
              <h1 className="">Network1 Ecosystem</h1>
              <p>
                An NFT is a digital asset that represents real-world objects
                like art, music, in-game items and videos. They are bought and
                sold online, frequently with cryptocurrency, and they are
                generally encoded with the same underlying software as many
                cryptos.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
