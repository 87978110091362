import React from "react";

export default function JoinUs() {
  return (
    <>
      <div className="container py-5 my-md-5">
        <div className="row">
          <div className="col-lg-6">
            <div className="">
              <h2 className="text-white">Ready to join us?</h2>

              <p className="text-white ">
                Join our vibrant community of developers & blockchain
                enthusiasts
                <br />
                who collectively work together to for the growth & expansion of
                Network1 ecosystem!
              </p>
            </div>
          </div>
          <div className="col-xl-6 d-flex justify-content-center align-items-centerol">
            <img
              style={{ width: "60%" }}
              src="assets/images/img-one/ready_to_join_us.png"
            />
          </div>
        </div>
      </div>
    </>
  );
}
