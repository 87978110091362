import React from "react";

export default function Infrastructure() {
  return (
    <>
      <div className="container py-5 my-md-5">
        <div className="row">
          <div className="col-xl-6">
            <p className="text-color-ev text-style-allcaps">
              Built with latest tech-stack
            </p>
            <h2 className="text-white">Network1 Infrastructure </h2>
            <p className="text-white">
              Network1 hosts one of the most secure & reliable blockchain
              infrastructure that enables seamless transactions. It uses the
              latest tech-stack to offer developers a highly scalable, low-cost
              & adaptable blockchain platform.
            </p>
            <div className="divider"></div>
            <div className="_2-col_stat-grid">
              <div className="_2-col_stat-item">
                <h3 className=" text-white _2-col_stat-item">1K TPS</h3>
                <p className=" text-white p-grey">Hyper-Speed Transactions</p>
              </div>
              <div className="_2-col_stat-item">
                <h3 className="text-white _2-col_stat-item">$0.01</h3>
                <p className=" text-white p-grey">Low Gas Fees</p>
              </div>
              <div className="_2-col_stat-item">
                <h3 className=" text-white _2-col_stat-item">1 Sec</h3>
                <p className=" text-white p-grey">Blocktime</p>
              </div>
              <div className=" text-white _2-col_stat-item">
                <h3 className=" text-white _2-col_stat-item">~10kb</h3>
                <p className=" text-white p-grey">Block size</p>
              </div>
            </div>
            <br />
            <div className="button-group">
              {/* <a className="button-Whitepaper">
                <button className="w-button " href="">
                  Learn more
                </button>
              </a> */}
              <a className="fugu--btn bg-blue active rounded-pill">
                Learn more
              </a>
              <a
                className="arrow-button w-inline-block"
                href="/network1.gitbook.io/networkchain"
                target="_blank"
              >
                Visit docs
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="currentColor"
                    class="bi bi-arrow-right"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"
                    />
                  </svg>
                </span>
              </a>
            </div>
            <br />
          </div>
          <div className="col-xl-6 d-flex justify-content-center align-items-centerol">
            <img
              style={{ width: "95%" }}
              src="assets/images/img-one/network1-infrastructure.png"
            />
          </div>
        </div>
      </div>
    </>
  );
}
