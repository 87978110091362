import React from "react";

export default function NetEx() {
  return (
    <>
      <div className="fugu--content-section pt-5">
        <div className="container">
          <div className="fugu--content-top">
            <div className="row align-items-center">
              <div className="col-xl-6 d-flex justify-content-center align-items-center order-xl-2">
                <img
                  src="assets/images/img-one/netex.svg"
                  className="w-100"
                  alt=""
                />
              </div>
              <div className="col-xl-6">
                <div className="fugu--default-content">
                  <h2 className="text-color-zk text-style-allcaps">Net1Ex</h2>
                  <p className="">
                    Net1Ex is a cryptocurrency exchange platform that aims to
                    provide a seamless trading experience for both novice and
                    experienced traders. It offers a broad range of digital
                    assets, enabling users to buy, sell, and trade NET1 and
                    other various cryptocurrencies in a secure and user-friendly
                    environment. Net1Ex is designed with advanced trading tools
                    and features, such as real-time market data, customizable
                    charts, and diverse order types, catering to the needs of
                    different trading strategies.
                  </p>{" "}
                  <br />
                </div>
                <div className="button-Whitepaper-w30 justify-content-center">
                  <a className="w-button" href="/net-ex">
                    Learn more
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
