import React from 'react'
import Header from '../Comman/Header'
import Footer from '../Comman/Footer'
import MineNetworkHero from './MineNetworkHero'
import MineNetworFeture from './MineNetworFeture'
import TelosFooter from '../Telos_net/TelosFooter'

export default function MineNetwork() {
  return (
      <>
          <Header />
          <MineNetworkHero />
          <MineNetworFeture/>
          <TelosFooter/>
          {/* <Footer/> */}
      </>
  )
}
