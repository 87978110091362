import React from "react";

export default function GettingStarted() {
  return (
    <>
      <div className="section bg-warning-200 fugu-section-padding3">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="py-4 text-start">
                <h2>Getting Started</h2>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 ">
              <div
                className="box-style fugu-iconbox-wrap2 wow fadeInUpX"
                data-wow-delay="0s"
                style={{
                  visibility: "visible",
                  animationDelay: "0s",
                  animationName: "fadeInUpX",
                }}
              >
                <div className="fugu-iconbox-icon2">
                  <img
                    src="assets/images/img-one/connection_details.png"
                    alt=""
                  />
                </div>
                <div className="fugu-iconbox-data2">
                  <h4>Environment Setup</h4>
                  <p>
                    Setup development environment & integrate Network1 to your
                    device.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div
                className="box-style fugu-iconbox-wrap2 wow fadeInUpX"
                data-wow-delay="0.20s"
                style={{
                  visibility: "visible",
                  animationDelay: "0.2s",
                  animationName: "fadeInUpX",
                }}
              >
                <div className="fugu-iconbox-icon2">
                  <img src="assets/images/img-one/test_tokens.png" alt="" />
                </div>
                <div className="fugu-iconbox-data2">
                  <h4>NET1 Faucet</h4>
                  <p>
                    Claim free Test NET1 coins & get started with development .
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
              <div
                className="box-style fugu-iconbox-wrap2 wow fadeInUpX"
                data-wow-delay="0.30s"
                style={{
                  visibility: "visible",
                  animationDelay: "0.3s",
                  animationName: "fadeInUpX",
                }}
              >
                <div className="fugu-iconbox-icon2">
                  <img
                    src="assets/images/img-one/network_explorer.png"
                    alt=""
                  />
                </div>
                <div className="fugu-iconbox-data2">
                  <h4>Tools</h4>
                  <p>
                    Explore all tools & technology to develop dApps on Network1.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
