import React from "react";

export default function NboxHero() {
  return (
    <>
      <div
        className="fugu--hero-section bg-white"
        style={{
          backgroundImage: `url('assets/images/img-one/n1_network-bg.png')`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "50% 50%",
        }}
      >
        <div id="fugu--counter" />
        <div className="container">
          <div className="row">
            <div className="col-lg-7">
              <div className="fugu--hero-content">
                <h1 className="wow fadeInUpX " data-wow-delay="0s">
                  Empowering Networkers with Utility
                </h1>
                <p className="wow fadeInUpX " data-wow-delay="0.10s">
                  NBOX is a revolutionary utility token within the Network1
                  Chain ecosystem, designed to empower networkers and facilitate
                  seamless transactions, interactions, and value exchange within
                  the platform. With its innovative features and wide-ranging
                  utility, NBOX serves as the backbone of the Network1 Chain
                  ecosystem, driving engagement, incentivizing participation,
                  and enhancing the overall user experience.
                </p>
                <div
                  className="fugu--btn-wrap fugu--hero-btn wow fadeInUpX"
                  data-wow-delay="0.20s"
                >
                  <a className="fugu--btn bg-gray active" href="#">
                    Get Started
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-5">
              <img
                src="assets/images/img-one/NetBox-Phn.png"
                className="w-100"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
