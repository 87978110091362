import React, { useState } from "react";

export default function Header() {
  const [show, setShow] = useState(false);
  const [showSubMenu, setShowSubMenu] = useState("");

  return (
    <>
      <header
        className="site-header fugu--header-section fugu--header-three"
        id="sticky-menu"
      >
        <div className="container-fluid">
          <nav className="navbar site-navbar header-new-css">
            {/* Brand Logo*/}
            <div className="brand-logo rt-mr-20">
              <a href="/">
                <img
                  src="assets/images/img-one/N1_logo.png"
                  alt=""
                  className="light-version-logo"
                  style={{ width: "220px" }}
                />
              </a>
            </div>
            <div className="menu-block-wrapper">
              <div className={show ? "menu-overlay active" : "menu-overlay"} />
              <nav
                className={show ? "menu-block active" : "menu-block"}
                id="append-menu-header"
              >
                <div
                  className={
                    showSubMenu ? "mobile-menu-head active" : "mobile-menu-head"
                  }
                >
                  <div className="go-back">
                    <i
                      className="fa fa-angle-left"
                      onClick={() => setShowSubMenu("")}
                    />
                  </div>
                  <div className="current-menu-title" />
                  <div
                    className="mobile-menu-close"
                    onClick={() => setShow(false)}
                  >
                    ×
                  </div>
                </div>
                <ul className="site-menu-main">
                  <li className="nav-item nav-item-has-children">
                    <a className="nav-link-item drop-trigger">
                      Developer{" "}
                      <i
                        className="fas fa-angle-down"
                        onClick={() => setShowSubMenu("developer")}
                      />
                    </a>
                    <ul
                      className={
                        showSubMenu === "developer"
                          ? "sub-menu active"
                          : "sub-menu"
                      }
                      id="submenu-15"
                    >
                      <li className="sub-menu--item">
                        <a href="/build">Build</a>
                      </li>
                      <li className="sub-menu--item">
                        <a href="/developer">Developer Resource</a>
                      </li>
                      <li className="sub-menu--item">
                        <a
                          href="https://network1.gitbook.io/documentation"
                          target="_blank"
                        >
                          Documentation
                        </a>
                      </li>
                      <li className="sub-menu--item">
                        <a href="/coming-soon">Join bug bounty</a>
                      </li>
                      <li className="sub-menu--item">
                        <a href="https://net1scan.com" target="_blank">
                          Network Explorer
                        </a>
                      </li>
                      <li className="sub-menu--item">
                        <a
                          href="https://networkchain.gitbook.io/technical-paper/"
                          target="_blank"
                        >
                          Technical Paper
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li className="nav-item nav-item-has-children">
                    <a className="nav-link-item drop-trigger">
                      Enterprise
                      <i
                        className="fas fa-angle-down"
                        onClick={() => setShowSubMenu("enterprise")}
                      />
                    </a>
                    <ul
                      className={
                        showSubMenu === "enterprise"
                          ? "sub-menu active"
                          : "sub-menu"
                      }
                      id="submenu-2"
                    >
                      <li className="sub-menu--item">
                        <a href="/product">Products</a>
                      </li>
                      <li className="sub-menu--item nav-item-has-children">
                        <a
                          href="/ecosystem"
                          data-menu-get="h3"
                          className="drop-trigger"
                        >
                          Ecosystem
                        </a>
                      </li>
                      <li className="sub-menu--item">
                        <a
                          href="https://medium.com/@netchainofficial/b ecome-a-miner-9860c1544dc1"
                          target="_blank"
                        >
                          Become an Miner
                        </a>
                      </li>
                      <li className="sub-menu--item nav-item-has-children">
                        <a
                          href="/governance"
                          data-menu-get="h3"
                          className="drop-trigger"
                        >
                          Governance
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li className="nav-item nav-item-has-children has-megamenu">
                    <a
                      href="/network-studio"
                      className="nav-link-item drop-trigger"
                    >
                      Nework Studio
                    </a>
                  </li>
                  <li className="nav-item nav-item-has-children">
                    <a
                      href="https://validator.network1.tech/"
                      target="_blank"
                      className="nav-link-item drop-trigger"
                    >
                      Validator
                    </a>
                  </li>
                  {/* <li className="nav-item nav-item-has-children">
                    <a href="/bridge" className="nav-link-item drop-trigger">
                      Bridge
                    </a>
                  </li> */}
                  {/* <li className="nav-item nav-item-has-children">
                    <a href="/company" className="nav-link-item drop-trigger">
                      Company <i className="fas fa-angle-down" />
                    </a>
                    <ul className="sub-menu" id="submenu-15">
                      <li className="sub-menu--item">
                        <a href="">contact dark</a>
                      </li>
                      <li className="sub-menu--item">
                        <a href="">contact light 01</a>
                      </li>
                      <li className="sub-menu--item">
                        <a href="">contact light 02</a>
                      </li>
                    </ul>
                  </li> */}
                  {/* <li className="nav-item nav-item-has-children">
                    <a href="#" className="nav-link-item drop-trigger">
                      More <i className="fas fa-angle-down" />
                    </a>
                    <ul className="sub-menu" id="submenu-15">
                      <li className="sub-menu--item">
                        <a href="">Research paper</a>
                      </li>
                      <li className="sub-menu--item">
                        <a href="contact.html">contact light 01</a>
                      </li>
                      <li className="sub-menu--item">
                        <a href="contact-light.html">contact light 02</a>
                      </li>
                    </ul>
                  </li> */}
                </ul>
              </nav>
            </div>
            {/* <div className="header-btn header-btn-l1 ms-auto d-none d-xs-inline-flex">
              <a className="fugu--btn fugu--menu-btn3" href="contact.html">
                Get Started
              </a>
            </div> */}
            {/* mobile menu trigger */}
            <div className="mobile-menu-trigger" onClick={() => setShow(true)}>
              <span />
            </div>
            {/*/.Mobile Menu Hamburger Ends*/}
          </nav>
        </div>
      </header>
    </>
  );
}
