import React from 'react'
import Header from '../Comman/Header'
import Footer from '../Comman/Footer'
import NetFxHero from './NetFxHero'
import NetFxFeature from './NetFxFeature'
import WhyChoosNetFx from './WhyChoosNetFx'
import GetStarted from './GetStarted'
import TelosFooter from '../Telos_net/TelosFooter'

export default function NetFx() {
    return (
        <>
            <Header />
            <NetFxHero/>
            <NetFxFeature /> 
            <WhyChoosNetFx />
            <GetStarted/>
            <TelosFooter/>
            {/* <Footer /> */}
        </>
    )
}
