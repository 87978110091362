import React from "react";

export default function StartBuilding() {
  return (
    <>
      <div>
        <div className="container py-5 my-md-5 position-relative">
          <div className="row">
            <div className="col-xl-6 d-flex justify-content-center align-items-centerol">
              <img
                src="assets/images/img-one/shade_bg.png"
                style={{ position: "absolute", top: "-100px", left: "-50%" }}
              />

              <img
                style={{ width: "100%" }}
                src="assets/images/img-one/build_on_network1.png"
              />
            </div>
            <div className="col-xl-6">
              <p className="text-color-zero text-style-allcaps">
                Start building dApps
              </p>
              <h2 className="text-white">Build on Network1</h2>
              <p className="text-white">
                Network1 demonstrates an easy developer experience to build &
                launch various decentralized applications (dApps). Its
                versatility and applicability across various industries and
                business models through a wide range of use cases.
              </p>
              <br />
              <div className="button-group">
                {/* <a className="button-Whitepaper" href="/build">
                <button className="w-button " href="">
                  Build
                </button>
              </a> */}
                <a className="fugu--btn bg-blue active rounded-pill">Build</a>
                <a
                  className="arrow-button w-inline-block "
                  href="/network1.gitbook.io/networkchain"
                  target="_blank"
                >
                  Docs
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="currentColor"
                      class="bi bi-arrow-right"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"
                      />
                    </svg>
                  </span>
                </a>
              </div>
              <br />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
