import React from 'react'
import Header from '../Comman/Header'
import Footer from '../Comman/Footer'
import GrantsHero from './GrantsHero'
import Minig from './Minig'
import Allocation from '../Home/Allocation'
import LiquidStaking from './LiquidStaking'
import RewardsRate from './RewardsRate'
import HowStart from './HowStart'
import Project from './Project'
import Feature from './Feature'
import WhyChoos from './WhyChoos'
import SocialMedia from '../Comman/SocialMedia'
import TelosFooter from '../Telos_net/TelosFooter'

export default function Grants() {
    return (
        <>
            <Header />
            <GrantsHero />
            <Minig />
            <Feature />
            <WhyChoos/>
            <Allocation />
            <SocialMedia/>
            <TelosFooter/>
            {/* <Footer /> */}
        </>
    )
}
