import React from "react";

function StableValue() {
  return (
    <div>
      <div className="fugu--feature-section bg-white fugu--section-padding2">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-4 col-md-6">
              <div
                className="fugu--iconbox-wrap fugu--iconbox-wrap3 box-white-wrap4 wow fadeInUpX"
                data-wow-delay="0s"
                style={{
                  visibility: "visible",
                  animationDelay: "0s",
                  animationName: "fadeInUpX",
                }}
              >
                <div className="fugu--iconbox-thumb">
                  <img
                    src="assets/images/img-one/stable_value.png"
                    width="70"
                    alt=""
                  />
                </div>
                <div className="fugu--iconbox-data">
                  <h4 className="text-black">Stable Value</h4>
                  <p className="text-black">
                    USDN is a stablecoin pegged to the value of $1, providing a
                    reliable medium of exchange within the Network1 blockchain
                    ecosystem.
                  </p>
                </div><br/>
              </div>
            </div>
            <div className="col-xl-4 col-md-6">
              <div
                className="fugu--iconbox-wrap fugu--iconbox-wrap3 box-white-wrap4 wow fadeInUpX"
                data-wow-delay="0s"
                style={{
                  visibility: "visible",
                  animationDelay: "0s",
                  animationName: "fadeInUpX",
                }}
              >
                <div className="fugu--iconbox-thumb">
                  <img
                    src="assets/images/img-one/decentralized_nature.png"
                    width="70"
                    alt=""
                  />
                </div>
                <div className="fugu--iconbox-data">
                  <h4 className="text-black">Decentralized Nature</h4>
                  <p className="text-black">
                    As a decentralized currency, USDN operates without a central
                    authority, offering transparency, security, and trust for
                    users in transactions and payments.
                  </p>
                </div><br/>
              </div>
            </div>
            <div className="col-xl-4 col-md-6">
              <div
                className="fugu--iconbox-wrap fugu--iconbox-wrap3 box-white-wrap4 wow fadeInUpX"
                data-wow-delay="0s"
                style={{
                  visibility: "visible",
                  animationDelay: "0s",
                  animationName: "fadeInUpX",
                }}
              >
                <div className="fugu--iconbox-thumb">
                  <img
                    src="assets/images/img-one/integration_with_network1.png"
                    width="70"
                    alt=""
                  />
                </div>
                <div className="fugu--iconbox-data">
                  <h4 className="text-black">Integration with Network1</h4>
                  <p className="text-black">
                    USDN is specifically designed for the Network1 blockchain
                    ecosystem, making it an ideal choice for payments,
                    transactions, and value transfers within this environment.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StableValue;
