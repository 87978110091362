import React from 'react'

export default function WhyChoosNetworkChain() {
    return (
        <>
            <div className="fugu--content-section pt-5">
                <div className="container">
                    <div className="fugu--content-top">
                        <div className="row">
                            <div className="col-xl-6 d-flex justify-content-center align-items-center order-xl-2">
                                <div className="fugu--content-thumb">
                                    <img className="wow fadeInUpX" data-wow-delay=".10s" src="assets/images/all-img/v5/thumb.png" alt="" style={{ visibility: 'visible', animationDelay: '0.1s', animationName: 'fadeInUpX' }} />
                                    <div className="fugu--circle-shape circle-three">
                                        <img src="assets/images/all-img/shapes-round.png" alt="" />
                                        <div className="waves wave-1" />
                                    </div>
                                    <div className="fugu--circle-shape circle-four">
                                        <img src="assets/images/all-img/shapes-round.png" alt="" />
                                        <div className="waves wave-1" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-6">
                                <div className="fugu--default-content">
                                    <h2>Why Choose Net1Ex?</h2>
                                    <div className="fugu--meta">
                                        <ul>
                                            <li><img src="assets/images/svg2/check.svg" alt="" />Reliable and Secure: Trust is essential in the world of cryptocurrency trading. With Net1Ex, you can trade with confidence, knowing that your funds and personal information are safe and secure.
                                            </li>
                                            <li><img src="assets/images/svg2/check.svg" alt="" />
                                                User-Friendly: Whether you're a beginner or an experienced trader, Net1Ex offers a user-friendly interface and intuitive trading experience that makes it easy to buy, sell, and trade cryptocurrencies.
                                            </li>
                                            <li><img src="assets/images/svg2/check.svg" alt="" />Wide Range of Assets: With a diverse selection of cryptocurrencies available for trading, Net1Ex offers something for everyone, from popular coins like Bitcoin and Ethereum to up-and-coming altcoins and tokens.
                                            </li>
                                            <li><img src="assets/images/svg2/check.svg" alt="" />Advanced Trading Tools: Take advantage of advanced trading tools and features to analyze the market, identify trends, and make informed trading decisions.
                                            </li>
                                            <li><img src="assets/images/svg2/check.svg" alt="" />Responsive Customer Support: Our dedicated customer support team is available around the clock to assist you with any questions or issues you may have, ensuring a seamless trading experience.
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
