import React from 'react'

export default function JoinUs() {
    return (
        <>
            <div className="fugu-cta-section bg-white">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="fugu-default-content large-content">
                                <h2 className='span1'>Learn More
                                    Read technical Whitepaper</h2>
                            </div>
                        </div>
                        <div className="col-lg-4 d-flex align-items-center">
                            <div className="fugu-cta-app-btn-wrap">
                                <a className="fugu--btn bg-blue active" href="#">
                                    Download
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
