import React from "react";
import Header from "../Comman/Header";
import Footer from "../Comman/Footer";
import NboxHero from "./NboxHero";
import NboxFeature from "./NboxFeature";
import NboxAddditonalPoint from "./NboxAddditonalPoint";
import WhyChoosNbox from "./WhyChoosNbox";
import TelosFooter from "../Telos_net/TelosFooter";

export default function Nbox() {
  return (
    <>
      <Header />
      <NboxHero />
      <NboxFeature />
      <WhyChoosNbox />
      <NboxAddditonalPoint />
      <TelosFooter />
      {/* <Footer /> */}
    </>
  );
}
