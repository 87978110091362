import React from "react";

export default function Decenterlize() {
  return (
    <>
      <div className="section bg-warning-200 fugu-section-padding3">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12">
              <div className="py-4 text-center">
                <h2>How it works?</h2>
                <p>
                  Governance enable true decentralization in managing the
                  Network1 ecosystem.
                </p>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-8 col-md-6">
              <div
                className=" fugu-iconbox-wrap2 wow fadeInUpX d-flex justify-content-between"
                // className="box-white-wrap4"
                data-wow-delay="0s"
                style={{
                  visibility: "visible",
                  animationDelay: "0s",
                  animationName: "fadeInUpX",
                }}
              >
                <div className="fugu-iconbox-icon2">
                  <img src="assets/images/img-one/proposal.png" alt="" />
                </div>
                <div className="fugu-iconbox-data2">
                  <h4 className="text-end">PROPOSAL</h4>
                  <p>
                    A new proposal is introduced to governance. To propose the
                    change, the proposer must stake predefined NET1 as deposit.
                    The proposal must pass the community surveillance audit in
                    forum discussion before moving further for voting.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-end">
            <div className="col-lg-8 col-md-6">
              <div
                className="fugu-iconbox-wrap2 wow fadeInUpX d-flex justify-content-between"
                // className="box-white-wrap4"
                data-wow-delay="0.20s"
                style={{
                  visibility: "visible",
                  animationDelay: "0.2s",
                  animationName: "fadeInUpX",
                }}
              >
                <div className="fugu-iconbox-data2">
                  <h4>VOTING</h4>
                  <p>
                    Network1 community votes either Yes, No or Abstain. Network1
                    community & NE1 holders decide if the proposal needs to be
                    considered for upgradation using their voting power. It
                    requires more than 50% votes to ‘YES’ to terminate the
                    change successfully. The proposal is rejected if the total
                    ‘NO’ votes ratio is higher than ‘YES’ and ‘Abstain’ votes
                    are excluded from either decision.
                  </p>
                </div>
                <div className="fugu-iconbox-icon2">
                  <img src="assets/images/img-one/voting.png" alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-8 col-md-6">
              <div
                className="fugu-iconbox-wrap2 wow fadeInUpX d-flex justify-content-between"
                // className="box-white-wrap4"
                data-wow-delay="0.30s"
                style={{
                  visibility: "visible",
                  animationDelay: "0.3s",
                  animationName: "fadeInUpX",
                }}
              >
                <div className="fugu-iconbox-icon2">
                  <img src="assets/images/img-one/termination.png" alt="" />
                </div>
                <div className="fugu-iconbox-data2">
                  <h4 className="text-end">TERMINATION</h4>
                  <p>
                    At the end of deadline, the snapshot records the decision.
                    If the proposal passes majority of ‘YES’ votes then protocol
                    is upgraded with proposed change and all network
                    participants shall follow the change. In case of rejection,
                    the proposer loses its deposit and it is added to the
                    community pool.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
